import React, { useEffect, useState } from "react";
import "./NewServicesSectionCard.scss";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { CgCloseO } from "react-icons/cg";
import logo from "../../assets/service-imgs/modal/logo_low_op.png";
import loader from "../../assets/custom_loader.gif";
import GoogleModal from "./GoogleModal";
// import logo  from '../../assets/logo.png';

const NewServiceSectionCard = (props) => {
  const {
    image,
    icon,
    title,
    desc,
    moreBtnType,
    border,
    modalInfo,
    modalInfo2,
    modalType,
  } = props;

  const [style, setStyle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [webIndex, setWebIndex] = useState(0);

  const handleIsLoading = () => setIsLoading(false);

  const editModalStyle = () => {
    if (window.innerWidth <= 600) {
      setStyle({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: 24,
        color: "#fff",
        borderRadius: "7px",
        outline: "none",
        padding: "0%",
        width: "85vw",
      });
    } else {
      setStyle({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "1716px",
        minWidth: "85vw",
        boxShadow: 24,
        color: "#fff",
        borderRadius: "7px",
        outline: "none",
        padding: "0%",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      editModalStyle();
    });

    editModalStyle();
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  function contactUs() {
    handleClose();
    var height = document.getElementsByClassName("Header")[0].offsetHeight + 15;
    var container = document.getElementsByClassName("App")[0];
    var offsetTop;
    offsetTop =
      document.getElementsByClassName("ContactUsSection")[0].offsetTop - height;
    container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
  }

  const handleWebOpen = (index) => {
    setWebIndex(index);
    setIsLoading(true);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className={`service-card-items ${border ? border : ""}`}>
        <div className="service-card-items--body">
          <div className="service-card-items--text_container">
            <div className="service-card-items--icon">
              <img src={icon} alt="service_icon"></img>
            </div>
            <div className="service-card-items--title">
              <h2>{title}</h2>
            </div>
            <div className="service-card-items--desc">
              <p>{desc}</p>
            </div>
            {moreBtnType ? (
              <div className="service-card-items--btn_box">
                <a
                  className="service-card-items--more_btn"
                  onClick={handleOpen}
                >
                  view more
                </a>
              </div>
            ) : (
              <div className="service-card-items--btn_box service-card-items--other_btn_box ">
                <button
                  onClick={() => {
                    handleWebOpen(0);
                  }}
                  className="service-card-items--other_btn"
                >
                  Website
                </button>{" "}
                <button
                  onClick={() => {
                    handleWebOpen(1);
                  }}
                  className="service-card-items--other_btn"
                >
                  Web-App
                </button>
              </div>
            )}
          </div>
          <div className="service-card-items--image_container">
            <img src={image} alt="services_img"></img>
          </div>
          <div
            className={`service-card-items--overlay ${border ? border : ""}`}
          ></div>
        </div>
      </div>

      {/* Modal here */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CgCloseO onClick={handleClose} className="Modal-close--icon" />

            {modalType !== 3 ? (
              <>
                {" "}
                {isLoading && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      zIndex: "99999",
                      position: "absolute",
                      background: "#191B25",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "150px", height: "150px" }}
                      src={loader}
                      alt="services_img"
                    ></img>
                  </div>
                )}
                <div className="Modal-content">
                  <div className="Modal-content--left">
                    {modalType !== 2 ? (
                      <img
                        onLoad={handleIsLoading}
                        src={modalInfo?.image}
                        alt="modal_image"
                      ></img>
                    ) : (
                      <img
                        onLoad={handleIsLoading}
                        src={
                          webIndex === 0 ? modalInfo?.image : modalInfo2?.image
                        }
                        alt="modal_image"
                      ></img>
                    )}
                  </div>
                  <div className="Modal-content--right">
                    <div className="Modal-content--right_textBox">
                      {modalType === 1 ? (
                        <>
                          <div className="service-card-items--icon">
                            <img src={icon} alt="service_icon"></img>
                          </div>
                          <h2>{title}</h2>
                          <h3>{desc}</h3>
                          <p>{modalInfo?.desc}</p>
                          {modalType === 1 && (
                            <div className="Modal-content--logo">
                              <img
                                src={logo}
                                style={{ width: "100%", opacity: "0.3" }}
                                alt="logo"
                              ></img>
                            </div>
                          )}
                        </>
                      ) : modalType === 2 ? (
                        <div>
                          {
                            <>
                              {webIndex === 0 ? (
                                <modalInfo.content
                                  icon={icon}
                                  title={modalInfo.title}
                                  listTitle1={modalInfo.listTitle1}
                                  listTitle2={modalInfo.listTitle2}
                                  list1={modalInfo.list1}
                                  list2={modalInfo.list2}
                                  contactUs={contactUs}
                                />
                              ) : (
                                <modalInfo.content
                                  icon={icon}
                                  title={modalInfo2.title}
                                  listTitle1={modalInfo2.listTitle1}
                                  listTitle2={modalInfo2.listTitle2}
                                  list1={modalInfo2.list1}
                                  list2={modalInfo2.list2}
                                  contactUs={contactUs}
                                />
                              )}
                            </>
                          }
                        </div>
                      ) : (
                        <div>
                          <div className="service-card-items--icon">
                            <img src={icon} alt="service_icon"></img>
                          </div>
                          <div className="web-modal--desc">
                            <div className="web-modal--desc--title">
                              <h2>{modalInfo.title1}</h2>
                            </div>
                            <div className="web-modal--desc--main">
                              <p>{modalInfo.desc1}</p>
                            </div>
                          </div>
                          <div className="web-modal--desc">
                            <div className="web-modal--desc--title">
                              <h2>{modalInfo.title2}</h2>
                            </div>
                            <div className="web-modal--desc--main">
                              <p>{modalInfo.desc2}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="google-Modal-content">
                <GoogleModal />
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default NewServiceSectionCard;
