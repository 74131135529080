import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RouterLink from './Router/RouterLink';
import reportWebVitals from './reportWebVitals';

//import custom redux state provider
import { StateProvider } from './redux/StateProvider'

//redux reducer and redux inititial state
import reducer, { initialState } from './redux/reducer'

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <RouterLink />
  </StateProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
