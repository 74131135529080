import * as React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import A2zCaseStudyWeb from "../../assets/project-imgs/case-study/A2Z/Web-A2Z-case-study.png";
import BeautyClubCaseStudyApp from "../../assets/project-imgs/case-study/Beauty-club-Mobile-app/web-Beauty-case-study.png";
import BeautyClubCaseStudyWeb from "../../assets/project-imgs/case-study/Beauty-club-website/web-Beauty-club-website-case-study.png";
import LyxaCaseStudyApp from "../../assets/project-imgs/case-study/LYXA-app/Web-Lyxa-case-study.png";
import LyxaCaseStudyWeb from "../../assets/project-imgs/case-study/LYXA-website/Web-Lyxa-website-case-study.png";
import PlatinumCaseStudyWeb from "../../assets/project-imgs/case-study/Platinum/Web-Platinum-case-study.png";
import MBECaseStudyWeb from "../../assets/project-imgs/case-study/MBE/Web-MBE.png";
import NailzyCaseStudyWeb from "../../assets/project-imgs/case-study/Nailzi/Web-Nailzi-case-study.png";
import LogosCaseStudyWeb from "../../assets/project-imgs/case-study/LOGOS/Web-Logos.png";
import "./projectCarousel.scss";
// Modal
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { CgCloseO } from "react-icons/cg";
import LaptopIcon from "@mui/icons-material/Laptop";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import RightArrow from "../../assets/project-imgs/Arrow/right-big.svg";
import LeftArrow from "../../assets/project-imgs/Arrow/left-big.svg";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#e9d3a1",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "90%",
  color: "#fff",
  borderRadius: 1.2,
  outline: "none",
  boxShadow: 24,
  // overflowX: "hidden",
  // overflowY: "scroll",
  backgroundColor: "var(--dark-gray)",
};

const ProjectCarouselModal = ({
  open,
  setOpen,
  projectIndex,
  setProjectIndex,
  projects,
  setCounter,
  setStatus,
}) => {
  const [devices, setDevices] = React.useState("laptop");

  const handleClose = () => {
    setStatus((state) => !state);
    setOpen(false);
  };

  const handleDevices = (event, newDevices) => {
    if (newDevices !== null) {
      setDevices(newDevices);
    }
  };

  const handleBack = () => {
    if (projectIndex === 0) {
      setCounter(projects.length * 2 - 2);
      setProjectIndex(projects.length - 1);
    } else {
      setCounter((state) => state - 2);
      setProjectIndex((state) => state - 1);
    }
  };

  const handleNext = () => {
    if (projectIndex > projects.length - 2) {
      setCounter(0);
      setProjectIndex(0);
    } else {
      setCounter((state) => state + 2);
      setProjectIndex((state) => state + 1);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "sticky",
              top: "-1px",
              width: "100%",
              backgroundColor: "var(--dark-gray)",
            }}
          >
            <ThemeProvider theme={theme}>
              {projectIndex === 0 && (
                <ToggleButtonGroup
                  value={devices}
                  exclusive
                  onChange={handleDevices}
                  aria-label="device"
                  fullWidth
                  color="primary"
                  sx={{
                    // display: "flex",
                    // justifyContent: "center",
                    // position: "sticky",
                    // top: "-1px",
                    // backgroundColor: "var(--dark-gray)",
                    borderRadius: "0px",
                  }}
                >
                  <ToggleButton
                    sx={{
                      color: "white",
                      opacity: "50%",
                      "&:hover": {
                        opacity: "100%",
                      },
                    }}
                    value="laptop"
                    aria-label="laptop"
                  >
                    <LaptopIcon />
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      color: "white",
                      opacity: "50%",
                      "&:hover": {
                        opacity: "100%",
                      },
                    }}
                    value="phone"
                    aria-label="phone"
                  >
                    <PhoneAndroidIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              {projectIndex === 1 && (
                <ToggleButtonGroup
                  value={devices}
                  exclusive
                  onChange={handleDevices}
                  aria-label="device"
                  fullWidth
                  color="primary"
                  sx={{
                    // display: "flex",
                    // justifyContent: "center",
                    // position: "sticky",
                    // top: "-1px",
                    // backgroundColor: "var(--dark-gray)",
                    borderRadius: "0px",
                  }}
                >
                  <ToggleButton
                    sx={{
                      color: "white",
                      opacity: "50%",
                      "&:hover": {
                        opacity: "100%",
                      },
                    }}
                    value="laptop"
                    aria-label="laptop"
                  >
                    <LaptopIcon />
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      color: "white",
                      opacity: "50%",
                      "&:hover": {
                        opacity: "100%",
                      },
                    }}
                    value="phone"
                    aria-label="phone"
                  >
                    <PhoneAndroidIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </ThemeProvider>
            <motion.div
              className="CaseStudyClose"
              initial={{ opacity: 0.5 }}
              whileHover={{ opacity: 1 }}
            >
              <CgCloseO onClick={handleClose} className="CaseStudyCloseIcon" />
            </motion.div>
          </div>
          <div className="CaseStudyArrow">
            <motion.img
              onClick={handleBack}
              className="CaseStudyBack"
              src={LeftArrow}
              alt=""
              initial={{ opacity: 0.5 }}
              whileHover={{ opacity: 1 }}
            />
            <motion.img
              onClick={handleNext}
              className="CaseStudyNext"
              src={RightArrow}
              alt=""
              initial={{ opacity: 0.5 }}
              whileHover={{ opacity: 1 }}
            />
          </div>
          <div
            style={{
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            {projectIndex === 0 && devices === "laptop" && (
              <motion.img
                className="CaseStudyImage"
                src={BeautyClubCaseStudyWeb}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
            {projectIndex === 0 && devices === "phone" && (
              <motion.img
                className="CaseStudyImage"
                src={BeautyClubCaseStudyApp}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
            {projectIndex === 1 && devices === "laptop" && (
              <motion.img
                className="CaseStudyImage"
                src={LyxaCaseStudyWeb}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
            {projectIndex === 1 && devices === "phone" && (
              <motion.img
                className="CaseStudyImage"
                src={LyxaCaseStudyApp}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
            {projectIndex === 2 && (
              <motion.img
                className="CaseStudyImage"
                src={A2zCaseStudyWeb}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
            {projectIndex === 3 && (
              <motion.img
                className="CaseStudyImage"
                src={PlatinumCaseStudyWeb}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
            {projectIndex === 4 && (
              <motion.img
                className="CaseStudyImage"
                src={MBECaseStudyWeb}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
            {projectIndex === 5 && (
              <motion.img
                className="CaseStudyImage"
                src={NailzyCaseStudyWeb}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
            {projectIndex === 6 && (
              <motion.img
                className="CaseStudyImage"
                src={LogosCaseStudyWeb}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.5,
                }}
              />
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProjectCarouselModal;
