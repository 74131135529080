import React from "react";
import "./TeamMembers2.scss";

import KadriTechSolar from "../SolarAnimation/KadriTechSolarAnimation";
function TeamMember2() {
  return (
    <div className="newTeamMembers">
      <div className="newTeamMember">
        <div className="newTeamMemberInfoContainer" style={{ zIndex: '999999' }}>
          <div className="newTeamMemberInfoBox">
            <h4>Meet our creative</h4>
            <h2>team members</h2>
            <div className="newTeamMemberInfoDesc">
              <p>
              Our team of skilled, collaborative, and innovative developers is dedicated to delivering high-quality software solutions. They work seamlessly together, leveraging their exceptional technical skills and deep knowledge of coding languages and frameworks to exceed client expectations. With a passion for continuous learning, they stay ahead of the technological curve, ensuring our company remains at the forefront of the industry. Their cohesive teamwork consistently produces outstanding results, propelling our company's success in the dynamic world of computer science.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="newTeamMemberLeft">

        </div> */}
        <div className="newTeamMemberSolar">
          <KadriTechSolar />
        </div>
      </div>
    </div>
  );
}

export default TeamMember2;
