import React from "react";
import "./projectCarousel.scss";

const MobileCarousel = ({ projects, projectIndex }) => {
  return (
    <div className="div-center gallery">
      <div id="carousel">
        <figure>
          {" "}
          <img src={projects[projectIndex].imgBottom1} alt="" />
        </figure>
        <figure>
          {" "}
          <img src={projects[projectIndex].imgBottom2} alt="" />
        </figure>
        <figure>
          {" "}
          <img src={projects[projectIndex].imgBottom3} alt="" />
        </figure>
        {/* <figure>
          {" "}
          <img src={projects[projectIndex].imgBottom1} alt="" />
        </figure>
        <figure>
          {" "}
          <img src={projects[projectIndex].imgBottom2} alt="" />
        </figure> */}
        {/* <figure>
          {" "}
          <img src={projects[projectIndex].imgBottom3} alt="" />
        </figure> */}
      </div>
    </div>
  );
};

export default MobileCarousel;
