import React from "react";
import "./GoogleModal.scss";
import img1 from "../../assets/service-imgs/modal/googlesworkspace.png";
import img2 from "../../assets/service-imgs/modal/googleCloudPartner.png";

const listLeft = [
  {
    title: "Header",
    desc: "Connect, Create and Collaborate",
  },
  {
    title: "Brief description",
    desc: "Get all your work done in one place and increase productivity by connecting your team remotely in a secure working environment.",
  },
  {
    title: "Header",
    desc: "Connect, Create and Collaborate",
  },
  {
    title: "CTA",
    desc: "Contact Us",
  },
];
const listRight = [
  {
    title: "Header",
    desc: "Migrate All Your Workload to the Cloud",
  },
  {
    title: "Brief description",
    desc: "Bridging innovation from wherever you are to the industry you want to lead.",
  },
  {
    title: "Infrastructure",
    desc: "Speed up your application development and deployment with our custom-built on-demand infrastructure.",
  },
  {
    title: "Security",
    desc: "Reduce exposure for our customers by eliminating “Vendor in the middle” risk and other vulnerabilities.",
  },
];

const list2Left = [
  {
    id: 1,
    desc: "Access Anytime Anywhere: Give your team the right platform to work seamlessly from anywhere at anytime",
  },
  {
    id: 2,
    desc: "Encourage Innovation: Provide your teams with to explore better ways of workingCost-Effective Solution: Save your company cost by doing away with expensive servers and hardware.",
  },
  {
    id: 3,
    desc: "Cost-Effective Solution: Save your company cost by doing away with expensive servers and hardware.",
  },
  {
    id: 4,
    desc: "Maximise Time: Save tonnes of hours for your team by optimizing the way they work with workspace AI-powered tools and smart writing suggestions.",
  },
];
const list2Right = [
  {
    id: 1,
    desc: "Enjoy extensive cloud network reach with higher availability, lower latency and better security making it less likely to be attacked, intercepted, or manipulated.",
  },
  {
    id: 2,
    desc: "Encourage Innovation: Provide your teams with to explore better ways of workingCost-Effective Solution: Save your company cost by doing away with expensive servers and hardware.",
  },
];

const cardListLeft = [
  {
    title: "171 Hours",
    desc: "saved per user per year, which is about 21 days per user per year",
  },
  {
    title: "20%",
    desc: "Reduction of on-demand tech support needed",
  },
  {
    title: "331%",
    desc: "ROI driven by Google Workspace",
  },
];

// Modal inner list type one
function ListTypeOne({ list }) {
  return (
    <div>
      {list.map((item, i) => (
        <div key={i} className="google-listContainer">
          <div className="google-listDot"></div>
          <div className="google-listItems">
            <span
              style={{
                font: "var(--medium-desc-fontsize)",
                color: "var(--base-white-gray)",
                fontWeight: "600",
              }}
            >
              {item.title}:
            </span>
            <span
              style={{
                font: "var(--medium-desc-fontsize)",
                color: "var(--base-gray)",
                fontWeight: "500",
                marginLeft: "4px",
              }}
            >
              {item.desc}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

// Modal lsit type two
function ListTypeTwo({ list, title }) {
  return (
    <div className="google-listType2Container">
      <h2>{title}</h2>
      <div className="google-listType2">
        {list.map((item, i) => (
          <div key={i} className="google-listContainerType2">
            <div className="google-listDot">
              <span
                style={{
                  fontWeight: "700",
                  font: "var(--medium-desc-fontSize)",
                }}
              >
                {i + 1}
              </span>
            </div>
            <div className="google-listItems">
              {" "}
              <p
                style={{
                  font: "var(--medium-desc-fontsize)",
                  color: "var(--base-gray)",
                  fontWeight: "400",
                  marginLeft: "4px",
                  margin: "0 0",
                }}
              >
                {item.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

// Modal card
function GoogleModalCardList({ list, title }) {
  return (
    <div className="google-cardContainer">
      <h2>{title}</h2>
      <div className="google-cardListContainer">
        {list.map((item, i) => (
          <div key={i} className="google-cardList">
            <div className="google-cardtitle">
              <span
                style={{
                  fontWeight: "700",
                  font: "var(--medium-desc-fontSize)",
                }}
              >
                {item.title}
              </span>
            </div>
            <div className="google-cardListItems">
              {" "}
              <p
                style={{
                  font: "var(--medium-desc-fontsize)",
                  color: "var(--base-gray)",
                  fontWeight: "400",
                  marginLeft: "4px",
                  margin: "0 0",
                }}
              >
                {item.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
// Modal content
function GoogleModalContent({ img, customeClass, title, children }) {
  return (
    <div>
      <div className="google-modalHeader">
        <div className={`google-modalHeader--left ${customeClass}`}>
          <img src={img} alt="google_photo"></img>
        </div>
        <div className="google-modalHeader--right">
          <h2>{title}</h2>
        </div>
      </div>
      <div className="google-modalMainContent">{children}</div>
    </div>
  );
}

// Main modal
function GoogleModal() {
  return (
    <div className="google-modalContainer">
      <div className="google-modal--content">
        <div className="google-modal--left">
          <GoogleModalContent
            img={img1}
            customeClass="google-workplace"
            title="Google Workspace
"
          >
            <ListTypeOne list={listLeft}></ListTypeOne>
            <ListTypeTwo
              list={list2Left}
              title={"Do More With Google Workspace"}
            />
            <GoogleModalCardList
              list={cardListLeft}
              title="How Google Workspace impacts your business"
            />
            <div className="modal-inner-button">
              {" "}
              <a href="#">Download Demo</a>
            </div>
          </GoogleModalContent>
        </div>
        <div className="google-modal--right">
          <GoogleModalContent
            img={img2}
            customeClass="google-cloudPartner"
            title={"Google Cloud Partner"}
          >
            <ListTypeOne list={listRight}/>
            <ListTypeTwo list={list2Right} title={"Network"} />
            <div style={{marginTop:"20px"}}>
            <ListTypeOne
              list={[
                {
                  title: "Data Mangement",
                  desc: "Gain more value by seamlessly unlocking your data. Accelerate development by predicting business outcomes from your data.",
                },
              ]}
            />
            </div>

            <div className="modal-inner-button">
              {" "}
              <a href="#">Download Demo</a>
            </div>
          </GoogleModalContent>
        </div>
      </div>
    </div>
  );
}

export default GoogleModal;
