import React from "react";
import "./homeSection.scss";
import TicketCarousel from "../ticket-carousel/TicketCarousel";
import { useStateValue } from "../../redux/StateProvider";

function HomeSection() {
  const [{ language }] = useStateValue();

  const navigate = () => {
    var height = document.getElementsByClassName("Header")[0].offsetHeight + 15;
    var container = document.getElementsByClassName("App")[0];
    var offsetTop =
      document.getElementsByClassName("ContactUsSection")[0].offsetTop - height;
    container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
  };

  return (
    <div className="HomeSection">
      <div className="HomeSection-left">
        <div className="HomeSection-leftChild">
          {language === "English" ? (
            <span className="HomeSection-leftChildTitle">
              We create the future with an amazing{" "}
              <span style={{ color: "#E9D3A1" }}>Experience</span> For You
            </span>
          ) : language === "Swedish" ? (
            <span className="HomeSection-leftChildTitle">
              Vi skapar framtiden med fantastiska{" "}
              <span style={{ color: "#E9D3A1" }}>Upplevelse</span> För dig
            </span>
          ) : null}

          {language === "English" ? (
            <p>
              Kadritech delivers fast and intuitive websites, mobile apps, and
              other digital solutions that enhance your workflow. Our solutions
              are uniquely designed to deliver tangible results and help you
              navigate the ever-evolving digital landscape. With top-quality web
              development, mobile app development, UI/UX design, and graphic
              design solutions, you can rest assured of scalable solutions that
              delivers value as your business grow.{" "}
            </p>
          ) : language === "Swedish" ? (
            <p>
              Kadritech levererar snabba och intuitiva webbplatser, mobilappar
              och andra digitala lösningar som förbättrar ditt arbetsflöde. Våra
              lösningar är unikt designade för att leverera påtagliga resultat
              och hjälpa dig navigera i det digitala landskapet i ständig
              utveckling. Med webb av högsta kvalitet utveckling, utveckling av
              mobilappar, UI/UX-design och grafik designlösningar kan du vara
              säker på skalbara lösningar som levererar värde när ditt företag
              växer.{" "}
            </p>
          ) : null}

          <div
            onClick={navigate}
            className="Contact-usButton"
            title="Contact Us"
          >
            {language === "English" ? (
              <span>Contact Us</span>
            ) : language === "Swedish" ? (
              <span>Kontakta Oss</span>
            ) : null}
          </div>
        </div>
      </div>

      <TicketCarousel />
    </div>
  );
}

export default HomeSection;

// {language === "English" ? (

//   ) : language === "Swedish" ? (

//   ) : null
// }
