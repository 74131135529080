import React from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import "./social.scss";
import image1 from "../../assets/social/img1.png";
import image2 from "../../assets/social/img2.png";
import image3 from "../../assets/social/img3.png";
import image4 from "../../assets/social/img4.png";
import image5 from "../../assets/social/img5.png";
import image6 from "../../assets/social/img6.png";
import image7 from "../../assets/social/img7.png";
import image8 from "../../assets/social/img8.png";
import image9 from "../../assets/social/img9.png";
import image10 from "../../assets/social/img10.png";

function Social() {
  return (
    <div className="social">
      <motion.img
        className="image1"
        src={image1}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 4,
        }}
      />
      <motion.img
        className="image2"
        src={image2}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 3.5,
        }}
      />
      <motion.img
        className="image3"
        src={image3}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 3,
        }}
      />
      <motion.img
        className="image4"
        src={image4}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 2.5,
        }}
      />
      <motion.img
        className="image5"
        src={image5}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 2,
        }}
      />
      <motion.img
        className="image6"
        src={image6}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 1.5,
        }}
      />
      <motion.img
        className="image7"
        src={image7}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 2,
        }}
      />
      <motion.img
        className="image8"
        src={image8}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 3,
        }}
      />
      <motion.img
        className="image9"
        src={image9}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 3.5,
        }}
      />
      <motion.img
        className="image10"
        src={image10}
        alt=""
        animate={{ scale: [1, 1.1, 1] }}
        transition={{
          // delay: 2,
          duration: 0.8,
          repeat: Infinity,
          repeatDelay: 4,
        }}
      />
    </div>
  );
}

export default Social;
