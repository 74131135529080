import React, { useEffect, useRef, useState } from "react";




function Orbit({
  img,
  animationDuration,
  size,
  title = "Name here",
  desc = "Description here",
  id,
  queue,
  queueSize,
  processQueue,
  currentService,
  setCurrentService,
  borderStyle="2px solid rgba(193, 198, 217, 0.18)",
}) {

  const orbitImg = useRef(null);
  const orbitMain = useRef(null);
  const [isShifted, setIsShifted] = useState(currentService === id ? true : false);
  

  const onAnimationIteration = () => {

    setIsShifted(() => {
      if ( id === queue[1]) {
        processQueue();
        setCurrentService(queue[1] || 1);
        return true
      };
      if (queueSize === queue[0]) {
        processQueue();
        setCurrentService(1);
        return true;
      }
      return false;
    })

  };


  const boxStyleBeforeSfhited = {
    animation: `orbit ${animationDuration} linear infinite`,
    width: `${size}`,
    height: `${size}`,
    border: borderStyle
  };
  const boxStyleAfterSfhited = {
    // animation: `none`,
    width: `${size}`,
    height: `${size}`,
    border:borderStyle
  };

  const imgStyleBeforeSfhited = {
    // animation: `rotate ${animationDuration} linear infinite`,
    bottom: "0",
    left: "50%",
    transform: "translate(-50%, 50%) scale(1)",
    zIndex: '-1',
    // transition: "all 3s ease-in-out"
  };
  // const imgStyleAfterSfhited = {
  //   // animation: `diagonalMove 3s linear 1`,
  //   bottom: "0",
  //   left: "50%",
  //   transform: "translate(-50%, 50%) scale(1)",
  //   zIndex: '-1',
  //   // transition:"all 3s ease-in-out"
  // };

  const teamInfoOverlayStyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    padding: "20px",
    backgroundColor: "rgba(233, 211, 161, 0.26)",
    opacity: `${currentService === id && isShifted ? "1" : "0"}`,
    transition: "opacity 3s ease-in-out",
    zIndex: "-1",
  };

  const teamInfoOverlayStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    padding: "40px",
    backgroundColor: "rgba(233, 211, 161, 0.26)",
    opacity: `${currentService === id && isShifted ? "1" : "0"}`,
    transition: "opacity 3s ease-in-out",
    zIndex: "-1",
  };
  const teamInfoOverlayStyle3 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    padding: "60px",
    backgroundColor: "rgba(233, 211, 161, 0.26)",
    opacity: `${currentService === id && isShifted ? "1" : "0"}`,
    transition: "opacity 3s ease-in-out",
    zIndex: "-1",
  };

  return (
    <>

      <div
        ref={orbitMain}
        onAnimationIteration={onAnimationIteration}
        className="orbit orbit-transition"
        style={currentService === id && isShifted ? boxStyleAfterSfhited : boxStyleBeforeSfhited}
      >
        <div
          ref={orbitImg}
          className={`image ${currentService === id && isShifted ? 'image-zome-in' :'image-zome-out'}`}
          // className="image image-transition"
          style={currentService === id && isShifted ? {}: imgStyleBeforeSfhited}
        >
          <img style={currentService === id && isShifted ?{animation:"none"}:{animation: `rotate ${animationDuration} linear infinite`}} src={img} alt="Image_1" />
          <div
            className="teamInfoBox"
            style={currentService === id && isShifted ? { opacity: "1" } : { opacity: "0" }}
          >
            <h3 className="teamInfoTitle">{title}</h3>
            <p className="teamInfoDesc">{desc}</p>
          </div>
          <div
            className="teamInfoOverlay-1"
            style={teamInfoOverlayStyle1}
          ></div>
          <div
            className="teamInfoOverlay-2"
            style={teamInfoOverlayStyle2}
          ></div>
          <div
            className="teamInfoOverlay-3"
            style={teamInfoOverlayStyle3}
          ></div>
        </div>
      </div>
    </>
  );
}

export default Orbit;
