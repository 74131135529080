import React, { Component } from 'react';
import { BrowserRouter as Router,Route} from "react-router-dom";

import App from "../App";
import Privacy from '../components/privacy_policy/privacy_policy'
import term_condition from '../components/term_condition/term_condition'
class RouterLink extends Component{
render(){


  return(
 <Router>
    

     <Route exact path='/' component={App} />
     <Route exact path='/privacy' component={Privacy} />
     <Route exact path='/term_condition' component={term_condition} />


 </Router>



  )
}
}


export default RouterLink;
