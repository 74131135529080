import React from 'react';

const Privacy = () => {
  return (
    <div style={{padding:10}}>


        <h1>Privacy Policy</h1>
    </div>
  );
}

export default Privacy;