import React from 'react';

const term_condition = () => {
  return (
    <div style={{padding:10}}>


        <h1>Term & Condition</h1>
    </div>
  );
}

export default term_condition;