import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import "./header.scss";
import { useStateValue } from "../../redux/StateProvider";

function Header() {
  const [{ language }] = useStateValue();

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 600) {
        document.getElementsByClassName("Header-right")[0].style.height =
          "fit-content";
        document.getElementsByClassName("Header-rightInner")[0].style.display =
          "flex";
        setIsOpen(false);
      } else {
        document.getElementsByClassName("Header-rightInner")[0].style.display =
          "none";
        document.getElementsByClassName("Header-right")[0].style.height = "0vh";
        setIsOpen(false);
      }
    });
  }, []);

  const openMenu = () => {
    setIsOpen(true);
    document.getElementsByClassName("Header-right")[0].style.height = "100vh";
    document.getElementsByClassName("Header")[0].style.backgroundColor =
      "black";
    setTimeout(() => {
      document.getElementsByClassName("Header-rightInner")[0].style.display =
        "flex";
    }, [300]);
  };
  const closeMenu = () => {
    setIsOpen(false);
    document.getElementsByClassName("Header-right")[0].style.height = "0";
    setTimeout(() => {
      // document.getElementsByClassName("Header")[0].style.backgroundColor =
      //   "rgba(0, 0, 0, 0.6)";
      document.getElementsByClassName("Header")[0].style.backgroundColor =
        "transparent";
    }, [400]);
    setTimeout(() => {
      document.getElementsByClassName("Header-rightInner")[0].style.display =
        "none";
    }, [300]);
  };

  const navigate = (location) => {
    var height = document.getElementsByClassName("Header")[0].offsetHeight + 15;
    var container = document.getElementsByClassName("App")[0];
    var offsetTop;

    if (location === "SERVICES" && window.innerWidth < 600) {
      height = height + 50;
    }
    if (window.innerWidth < 600) {
      closeMenu();
      height = height * 1.5;
    } else if (window.innerWidth > 600 && window.innerWidth < 1026) {
      height = height * 2.5;
    } else if (location === "SERVICES" && window.innerWidth > 1025) {
      height = height * 2.5;
    }

    switch (location) {
      case "HOME":
        container.scroll({ top: 0, left: 0, behavior: "smooth" });
        break;
      case "PROJECTS":
        offsetTop =
          document.getElementsByClassName("ProjectCarousel")[0].offsetTop -
          height;
        container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
        break;
      case "SERVICES":
        offsetTop =
          document.getElementsByClassName("new-services-section")[0].offsetTop -
          height +
          100;
        container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
        break;
      case "ABOUT US":
        offsetTop =
          document.getElementsByClassName("AboutUsSectionMain")[0].offsetTop -
          height;
        container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
        break;
      case "CONTACT US":
        offsetTop =
          document.getElementsByClassName("ContactUsSection")[0].offsetTop -
          height;
        container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <div className="Header">
      <div className="Header-inner">
        <img className="Header-logo" src={logo} alt=""></img>

        {/* <div className="Header-leftTextHolder">
            <img className="Header-logo" src={logo} alt=""></img>
            <h4 className="Header-leftTextOne">&#60;Kadri</h4>
            <h4 className="Header-leftTextTwo">Tech/&#62;</h4>
          </div> */}

        <div className="Header-right">
          {language === "English" ? (
            <div className="Header-rightInner">
              <h4
                onClick={() => {
                  navigate("HOME");
                }}
              >
                HOME
              </h4>
              {/* <h4
                onClick={() => {
                  navigate("SERVICES");
                }}
              >
                SERVICES
              </h4> */}
              <h4
                onClick={() => {
                  navigate("SERVICES");
                }}
              >
                IT SERVICES
              </h4>
              {/* <h4
                onClick={() => {
                  navigate("SERVICES");
                }}
              >
                CONSULTING SERVICES
              </h4> */}
              <h4
                onClick={() => {
                  navigate("PROJECTS");
                }}
              >
                PROJECTS
              </h4>
              <h4
                onClick={() => {
                  navigate("ABOUT US");
                }}
              >
                ABOUT US
              </h4>
              <h4
                onClick={() => {
                  navigate("CONTACT US");
                }}
              >
                CONTACT US
              </h4>
            </div>
          ) : language === "Swedish" ? (
            <div className="Header-rightInner">
              <h4
                onClick={() => {
                  navigate("HOME");
                }}
              >
                HEM
              </h4>
              <h4
                onClick={() => {
                  navigate("SERVICES");
                }}
              >
                VÅRA TJÄNSTER
              </h4>
              <h4
                onClick={() => {
                  navigate("PROJECTS");
                }}
              >
                PROJEKT
              </h4>
              <h4
                onClick={() => {
                  navigate("ABOUT US");
                }}
              >
                OM OSS
              </h4>
              <h4
                onClick={() => {
                  navigate("CONTACT US");
                }}
              >
                KONTAKTA OSS
              </h4>
            </div>
          ) : null}
        </div>
        <div
          onClick={isOpen ? closeMenu : openMenu}
          className="Header-hamburger"
        >
          {isOpen === false ? (
            <svg
              style={{ fill: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="96"
              height="96"
              viewBox="0 0 24 24"
            >
              <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="96"
              height="96"
              viewBox="0 0 24 24"
              style={{ fill: "white" }}
            >
              <path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z"></path>
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
