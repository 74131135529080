import React, { useState } from "react";
import "./contactUsForm.scss";
import emailjs from "emailjs-com";
import Map from "../../assets/ourClients/map.svg";
import Pin from "../../assets/ourClients/pin.svg";
import { motion } from "framer-motion/dist/framer-motion";

function ContactUsForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  //   const [subject, setSubject] = useState("");
  const [company, setCompany] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      message !== "" &&
      email !== "" &&
      name !== "" &&
      //   subject !== "" &&
      company !== ""
    ) {
      emailjs
        .sendForm(
          "GmailEmailProvider",
          "template_3lnn8ob",
          e.target,
          "user_BMZRABx8uoosuRRJilG22",
        )
        .then(
          (result) => {
            setEmail("");
            setCompany("");
            setMessage("");
            setName("");
            alert("Message sent successfully.");
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          },
        );
      e.target.reset();
    } else {
      alert("Not all required fields have been filled.");
    }
  };

  return (
    <div className="ContactUsSection">
      <form onSubmit={sendEmail} className="ContactUsForm">
        <span className="OurClients-title">Our Worldwide Clients</span>
        <span className="ContactUsForm-title">Let's Talk</span>
        <input
          onChange={(e) => {
            setName(e.target.value);
          }}
          name="name"
          placeholder="Name"
          className="ContactUsForm-input"
        ></input>
        <input
          onChange={(e) => {
            setCompany(e.target.value);
          }}
          name="company"
          placeholder="Company"
          className="ContactUsForm-input"
        ></input>
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          name="email"
          placeholder="Email"
          className="ContactUsForm-input"
        ></input>
        {/* <input
              onChange={(e) => {
                setSubject(e.target.value);
              }}
              name="subject"
              placeholder="Subject"
              className="ContactUsForm-input"
            ></input> */}

        <textarea
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          name="message"
          className="ContactUsForm-textarea"
          placeholder="Message"
        />
        <button
          type="submit"
          className="ContactUsForm-button"
          title="Contact Us"
        >
          <span>Send</span>
        </button>
      </form>
      <div className="OurClients">
        <img src={Map} alt="ourClients-map" />
        <motion.img
          id="pin1"
          src={Pin}
          alt="pin"
          animate={{ scale: [1, 1.3, 1] }}
          transition={{
            //   delay: 2,
            duration: 1,
            repeat: Infinity,
            repeatDelay: 0.5,
          }}
        />
        <p id="country1">Sweden</p>
        <p id="country2">Canada</p>
        <p id="country3">USA</p>
        <p id="country4">Nigeria</p>
        <p id="country5">Lebanon</p>
        <p id="country6">Saudi Arabia</p>
        <p id="country7">Dubai</p>
        <p id="country8">India</p>
        <p id="country9">Bangladesh</p>
      </div>
    </div>
  );
}

export default ContactUsForm;
