import React from "react";
import "./OurNewPartners.scss";
import quantum from "../../assets/aboutUs/quantum_cloud.png";
import kadrimedtech from "../../assets/aboutUs/kadrimedtech.png";
import kadrimedtechBg from "../../assets/aboutUs/medtechRobot.png";
import dataholic from "../../assets/aboutUs/dataholic.png";
import dataholicbg from "../../assets/aboutUs/dataholic bg.png";

const OurNewPartners = () => {
  return (
    <>
      <div className="lg-OurNewPartnersSection">
        <div className="OurNewPartnersSection-container">
          <div className="OurNewPartners_content">
            {/* <div className="OurNewPartnersLeftCards">
              <div className="OurNewPartnersLeftCard-body">
                <div className="OurNewPartnersLeftCard-left">
                  <img src={kadrimedtech} alt="KadriMedTech"></img>
                </div>
                <div className="OurNewPartnersLeftCard-right">
                  <div className="OurNewPartnersLeftCard-title">
                    KadriMedTech
                  </div>
                  <div className="OurNewPartnersLeftCard-subtitle">
                    <p>Our medical subsidiary</p>
                  </div>
                  <div className="OurNewPartnersLeftCard-location">
                    <span>
                      <svg
                        width="17"
                        height="22"
                        viewBox="0 0 17 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 5C7.75832 5 7.0333 5.21993 6.41661 5.63199C5.79993 6.04404 5.31928 6.62971 5.03545 7.31494C4.75162 8.00016 4.67736 8.75416 4.82205 9.48159C4.96675 10.209 5.3239 10.8772 5.84835 11.4017C6.3728 11.9261 7.04098 12.2833 7.76841 12.4279C8.49584 12.5726 9.24984 12.4984 9.93506 12.2145C10.6203 11.9307 11.206 11.4501 11.618 10.8334C12.0301 10.2167 12.25 9.49168 12.25 8.75C12.25 7.75544 11.8549 6.80161 11.1517 6.09835C10.4484 5.39509 9.49456 5 8.5 5ZM8.5 11C8.05499 11 7.61998 10.868 7.24997 10.6208C6.87996 10.3736 6.59157 10.0222 6.42127 9.61104C6.25097 9.1999 6.20642 8.7475 6.29323 8.31105C6.38005 7.87459 6.59434 7.47368 6.90901 7.15901C7.22368 6.84434 7.62459 6.63005 8.06105 6.54323C8.4975 6.45642 8.9499 6.50097 9.36104 6.67127C9.77217 6.84157 10.1236 7.12996 10.3708 7.49997C10.618 7.86998 10.75 8.30499 10.75 8.75C10.75 9.34674 10.5129 9.91903 10.091 10.341C9.66903 10.7629 9.09674 11 8.5 11ZM8.5 0.5C6.31273 0.502481 4.21575 1.37247 2.66911 2.91911C1.12247 4.46575 0.252481 6.56273 0.25 8.75C0.25 11.6938 1.61031 14.8138 4.1875 17.7734C5.34552 19.1108 6.64886 20.3151 8.07344 21.3641C8.19954 21.4524 8.34978 21.4998 8.50375 21.4998C8.65772 21.4998 8.80796 21.4524 8.93406 21.3641C10.356 20.3147 11.6568 19.1104 12.8125 17.7734C15.3859 14.8138 16.75 11.6938 16.75 8.75C16.7475 6.56273 15.8775 4.46575 14.3309 2.91911C12.7843 1.37247 10.6873 0.502481 8.5 0.5ZM8.5 19.8125C6.95031 18.5938 1.75 14.1172 1.75 8.75C1.75 6.95979 2.46116 5.2429 3.72703 3.97703C4.9929 2.71116 6.70979 2 8.5 2C10.2902 2 12.0071 2.71116 13.273 3.97703C14.5388 5.2429 15.25 6.95979 15.25 8.75C15.25 14.1153 10.0497 18.5938 8.5 19.8125Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </span>
                    <p>Stockholm, Sweden</p>
                  </div>
                  <div className="OurNewPartnersLeftCard-desc">
                    <p>
                      KMT Consulting offers specialist B2B consulting services
                      and networking opportunities for Life Science and
                      healthcare companies
                    </p>
                  </div>
                  <div className="OurNewPartnersLeftCard-btn">
                    <a href="https://med.kadritech.se">View More</a>
                  </div>
                </div>
              </div>
              <div className="OurNewPartnersLeftCard-body">
                <div className="OurNewPartnersLeftCard-left">
                  <img src={quantum} alt="quantum_cloud"></img>
                </div>
                <div className="OurNewPartnersLeftCard-right">
                  <div className="OurNewPartnersLeftCard-title">
                    Quantum Cloud
                  </div>
                  <div className="OurNewPartnersLeftCard-subtitle">
                    <p> Our representative in KSA</p>
                  </div>
                  <div className="OurNewPartnersLeftCard-location">
                    <span>
                      <svg
                        width="17"
                        height="22"
                        viewBox="0 0 17 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 5C7.75832 5 7.0333 5.21993 6.41661 5.63199C5.79993 6.04404 5.31928 6.62971 5.03545 7.31494C4.75162 8.00016 4.67736 8.75416 4.82205 9.48159C4.96675 10.209 5.3239 10.8772 5.84835 11.4017C6.3728 11.9261 7.04098 12.2833 7.76841 12.4279C8.49584 12.5726 9.24984 12.4984 9.93506 12.2145C10.6203 11.9307 11.206 11.4501 11.618 10.8334C12.0301 10.2167 12.25 9.49168 12.25 8.75C12.25 7.75544 11.8549 6.80161 11.1517 6.09835C10.4484 5.39509 9.49456 5 8.5 5ZM8.5 11C8.05499 11 7.61998 10.868 7.24997 10.6208C6.87996 10.3736 6.59157 10.0222 6.42127 9.61104C6.25097 9.1999 6.20642 8.7475 6.29323 8.31105C6.38005 7.87459 6.59434 7.47368 6.90901 7.15901C7.22368 6.84434 7.62459 6.63005 8.06105 6.54323C8.4975 6.45642 8.9499 6.50097 9.36104 6.67127C9.77217 6.84157 10.1236 7.12996 10.3708 7.49997C10.618 7.86998 10.75 8.30499 10.75 8.75C10.75 9.34674 10.5129 9.91903 10.091 10.341C9.66903 10.7629 9.09674 11 8.5 11ZM8.5 0.5C6.31273 0.502481 4.21575 1.37247 2.66911 2.91911C1.12247 4.46575 0.252481 6.56273 0.25 8.75C0.25 11.6938 1.61031 14.8138 4.1875 17.7734C5.34552 19.1108 6.64886 20.3151 8.07344 21.3641C8.19954 21.4524 8.34978 21.4998 8.50375 21.4998C8.65772 21.4998 8.80796 21.4524 8.93406 21.3641C10.356 20.3147 11.6568 19.1104 12.8125 17.7734C15.3859 14.8138 16.75 11.6938 16.75 8.75C16.7475 6.56273 15.8775 4.46575 14.3309 2.91911C12.7843 1.37247 10.6873 0.502481 8.5 0.5ZM8.5 19.8125C6.95031 18.5938 1.75 14.1172 1.75 8.75C1.75 6.95979 2.46116 5.2429 3.72703 3.97703C4.9929 2.71116 6.70979 2 8.5 2C10.2902 2 12.0071 2.71116 13.273 3.97703C14.5388 5.2429 15.25 6.95979 15.25 8.75C15.25 14.1153 10.0497 18.5938 8.5 19.8125Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </span>
                    <p>Jeddah, AlTahliah St.Saudi Arabia</p>
                  </div>
                  <div className="OurNewPartnersLeftCard-desc">
                    <p>
                      Quantum Cloud consultancy company to help healthcare
                      facilities to overcome their obstacles.
                    </p>
                  </div>
                  <div className="OurNewPartnersLeftCard-btn">
                    <a href="https://quantumcloudsa.com">View More</a>
                  </div>
                </div>
              </div>

              
            </div> */}

            {/* kadrimedTech */}
            <div className="OurNewPartnersLeftCards">
              <div className="OurNewPartnersRightCard-body">
                <div className="OurNewPartnersRightCard-img_top">
                  <img src={kadrimedtechBg} alt="card_image"></img>
                </div>
                <div className="OurNewPartnersRightCard-inner--content">
                  <div className="OurNewPartnersRightCard-inner_img">
                    <img src={kadrimedtech} alt="card_image"></img>
                  </div>
                  <div className="OurNewPartnersRightCard-text--content">
                    <div className="OurNewPartnersRightCard-title">
                      KadriMedTech
                    </div>
                    <div className="OurNewPartnersRightCard-subtitle">
                      Our Medical Subsidiary
                    </div>
                    <div className="OurNewPartnersRightCard-location">
                      <span>
                        <svg
                          width="17"
                          height="22"
                          viewBox="0 0 17 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 5C7.75832 5 7.0333 5.21993 6.41661 5.63199C5.79993 6.04404 5.31928 6.62971 5.03545 7.31494C4.75162 8.00016 4.67736 8.75416 4.82205 9.48159C4.96675 10.209 5.3239 10.8772 5.84835 11.4017C6.3728 11.9261 7.04098 12.2833 7.76841 12.4279C8.49584 12.5726 9.24984 12.4984 9.93506 12.2145C10.6203 11.9307 11.206 11.4501 11.618 10.8334C12.0301 10.2167 12.25 9.49168 12.25 8.75C12.25 7.75544 11.8549 6.80161 11.1517 6.09835C10.4484 5.39509 9.49456 5 8.5 5ZM8.5 11C8.05499 11 7.61998 10.868 7.24997 10.6208C6.87996 10.3736 6.59157 10.0222 6.42127 9.61104C6.25097 9.1999 6.20642 8.7475 6.29323 8.31105C6.38005 7.87459 6.59434 7.47368 6.90901 7.15901C7.22368 6.84434 7.62459 6.63005 8.06105 6.54323C8.4975 6.45642 8.9499 6.50097 9.36104 6.67127C9.77217 6.84157 10.1236 7.12996 10.3708 7.49997C10.618 7.86998 10.75 8.30499 10.75 8.75C10.75 9.34674 10.5129 9.91903 10.091 10.341C9.66903 10.7629 9.09674 11 8.5 11ZM8.5 0.5C6.31273 0.502481 4.21575 1.37247 2.66911 2.91911C1.12247 4.46575 0.252481 6.56273 0.25 8.75C0.25 11.6938 1.61031 14.8138 4.1875 17.7734C5.34552 19.1108 6.64886 20.3151 8.07344 21.3641C8.19954 21.4524 8.34978 21.4998 8.50375 21.4998C8.65772 21.4998 8.80796 21.4524 8.93406 21.3641C10.356 20.3147 11.6568 19.1104 12.8125 17.7734C15.3859 14.8138 16.75 11.6938 16.75 8.75C16.7475 6.56273 15.8775 4.46575 14.3309 2.91911C12.7843 1.37247 10.6873 0.502481 8.5 0.5ZM8.5 19.8125C6.95031 18.5938 1.75 14.1172 1.75 8.75C1.75 6.95979 2.46116 5.2429 3.72703 3.97703C4.9929 2.71116 6.70979 2 8.5 2C10.2902 2 12.0071 2.71116 13.273 3.97703C14.5388 5.2429 15.25 6.95979 15.25 8.75C15.25 14.1153 10.0497 18.5938 8.5 19.8125Z"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </span>
                      <p>Stockholm, Sweden</p>
                    </div>
                    <div className="OurNewPartnersRightCard-desc">
                      <p>
                        KMT Consulting offers specialist B2B consulting services
                        and networking opportunities for Life Science and
                        healthcare companies
                      </p>
                    </div>
                    <div className="OurNewPartnersRightCard-btn">
                      <a href="https://med.kadritech.se">View More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="OurNewPartnersRightCards">
              <div className="OurNewPartnersRightCard-body">
                <div className="OurNewPartnersRightCard-img_top">
                  <img src={dataholicbg} alt="card_image"></img>
                </div>
                <div className="OurNewPartnersRightCard-inner--content">
                  <div className="OurNewPartnersRightCard-inner_img">
                    <img src={dataholic} alt="card_image"></img>
                  </div>
                  <div className="OurNewPartnersRightCard-text--content">
                    <div className="OurNewPartnersRightCard-title">
                      Dataholic
                    </div>
                    <div className="OurNewPartnersRightCard-subtitle">
                      Google Parnter
                    </div>
                    <div className="OurNewPartnersRightCard-location">
                      <span>
                        <svg
                          width="17"
                          height="22"
                          viewBox="0 0 17 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 5C7.75832 5 7.0333 5.21993 6.41661 5.63199C5.79993 6.04404 5.31928 6.62971 5.03545 7.31494C4.75162 8.00016 4.67736 8.75416 4.82205 9.48159C4.96675 10.209 5.3239 10.8772 5.84835 11.4017C6.3728 11.9261 7.04098 12.2833 7.76841 12.4279C8.49584 12.5726 9.24984 12.4984 9.93506 12.2145C10.6203 11.9307 11.206 11.4501 11.618 10.8334C12.0301 10.2167 12.25 9.49168 12.25 8.75C12.25 7.75544 11.8549 6.80161 11.1517 6.09835C10.4484 5.39509 9.49456 5 8.5 5ZM8.5 11C8.05499 11 7.61998 10.868 7.24997 10.6208C6.87996 10.3736 6.59157 10.0222 6.42127 9.61104C6.25097 9.1999 6.20642 8.7475 6.29323 8.31105C6.38005 7.87459 6.59434 7.47368 6.90901 7.15901C7.22368 6.84434 7.62459 6.63005 8.06105 6.54323C8.4975 6.45642 8.9499 6.50097 9.36104 6.67127C9.77217 6.84157 10.1236 7.12996 10.3708 7.49997C10.618 7.86998 10.75 8.30499 10.75 8.75C10.75 9.34674 10.5129 9.91903 10.091 10.341C9.66903 10.7629 9.09674 11 8.5 11ZM8.5 0.5C6.31273 0.502481 4.21575 1.37247 2.66911 2.91911C1.12247 4.46575 0.252481 6.56273 0.25 8.75C0.25 11.6938 1.61031 14.8138 4.1875 17.7734C5.34552 19.1108 6.64886 20.3151 8.07344 21.3641C8.19954 21.4524 8.34978 21.4998 8.50375 21.4998C8.65772 21.4998 8.80796 21.4524 8.93406 21.3641C10.356 20.3147 11.6568 19.1104 12.8125 17.7734C15.3859 14.8138 16.75 11.6938 16.75 8.75C16.7475 6.56273 15.8775 4.46575 14.3309 2.91911C12.7843 1.37247 10.6873 0.502481 8.5 0.5ZM8.5 19.8125C6.95031 18.5938 1.75 14.1172 1.75 8.75C1.75 6.95979 2.46116 5.2429 3.72703 3.97703C4.9929 2.71116 6.70979 2 8.5 2C10.2902 2 12.0071 2.71116 13.273 3.97703C14.5388 5.2429 15.25 6.95979 15.25 8.75C15.25 14.1153 10.0497 18.5938 8.5 19.8125Z"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </span>
                      <p>Dubai, UAE</p>
                    </div>
                    <div className="OurNewPartnersRightCard-desc">
                      <p>Creating the Future of Modern Workspace</p>
                    </div>
                    <div className="OurNewPartnersRightCard-btn">
                      <a href="https://www.dataholic.info">View More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="OurNewPartnersLeftCard"></div> */}
          </div>
        </div>
      </div>
      {/* responsive */}
      <div className="sm-OurNewPartnersSection">
        <div className="OurNewPartnersSection-container">
          <div className="OurNewPartners_content">
            {/* <div className="OurNewPartnersLeftCards ">
              <h2 className="OurNewPartners-title--sm">Our Subsidiaries</h2>
              <div className="OurNewPartnersLeftCard-body newPartnersCard-1">
                <div className="OurNewPartnersLeftCard-left">
                  <img src={kadrimedtech} alt="KadriMedTech"></img>
                </div>
                <div className="OurNewPartnersLeftCard-right">
                  <div className="OurNewPartnersLeftCard-title">
                    KadriMedTech
                  </div>
                  <div className="OurNewPartnersLeftCard-subtitle">
                    <p>Our medical subsidiary</p>
                  </div>
                  <div className="OurNewPartnersLeftCard-location">
                    <span>
                      <svg
                        width="17"
                        height="22"
                        viewBox="0 0 17 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 5C7.75832 5 7.0333 5.21993 6.41661 5.63199C5.79993 6.04404 5.31928 6.62971 5.03545 7.31494C4.75162 8.00016 4.67736 8.75416 4.82205 9.48159C4.96675 10.209 5.3239 10.8772 5.84835 11.4017C6.3728 11.9261 7.04098 12.2833 7.76841 12.4279C8.49584 12.5726 9.24984 12.4984 9.93506 12.2145C10.6203 11.9307 11.206 11.4501 11.618 10.8334C12.0301 10.2167 12.25 9.49168 12.25 8.75C12.25 7.75544 11.8549 6.80161 11.1517 6.09835C10.4484 5.39509 9.49456 5 8.5 5ZM8.5 11C8.05499 11 7.61998 10.868 7.24997 10.6208C6.87996 10.3736 6.59157 10.0222 6.42127 9.61104C6.25097 9.1999 6.20642 8.7475 6.29323 8.31105C6.38005 7.87459 6.59434 7.47368 6.90901 7.15901C7.22368 6.84434 7.62459 6.63005 8.06105 6.54323C8.4975 6.45642 8.9499 6.50097 9.36104 6.67127C9.77217 6.84157 10.1236 7.12996 10.3708 7.49997C10.618 7.86998 10.75 8.30499 10.75 8.75C10.75 9.34674 10.5129 9.91903 10.091 10.341C9.66903 10.7629 9.09674 11 8.5 11ZM8.5 0.5C6.31273 0.502481 4.21575 1.37247 2.66911 2.91911C1.12247 4.46575 0.252481 6.56273 0.25 8.75C0.25 11.6938 1.61031 14.8138 4.1875 17.7734C5.34552 19.1108 6.64886 20.3151 8.07344 21.3641C8.19954 21.4524 8.34978 21.4998 8.50375 21.4998C8.65772 21.4998 8.80796 21.4524 8.93406 21.3641C10.356 20.3147 11.6568 19.1104 12.8125 17.7734C15.3859 14.8138 16.75 11.6938 16.75 8.75C16.7475 6.56273 15.8775 4.46575 14.3309 2.91911C12.7843 1.37247 10.6873 0.502481 8.5 0.5ZM8.5 19.8125C6.95031 18.5938 1.75 14.1172 1.75 8.75C1.75 6.95979 2.46116 5.2429 3.72703 3.97703C4.9929 2.71116 6.70979 2 8.5 2C10.2902 2 12.0071 2.71116 13.273 3.97703C14.5388 5.2429 15.25 6.95979 15.25 8.75C15.25 14.1153 10.0497 18.5938 8.5 19.8125Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </span>
                    <p>Stockholm, Sweden</p>
                  </div>
                  <div className="OurNewPartnersLeftCard-desc">
                    <p>
                      KMT Consulting offers specialist B2B consulting services
                      and networking opportunities for Life Science and
                      healthcare companies
                    </p>
                  </div>
                  <div className="OurNewPartnersLeftCard-btn">
                    <a href="https://med.kadritech.se">View More</a>
                  </div>
                </div>
              </div>
              <div className="OurNewPartnersLeftCard-body newPartnersCard-2">
                <div className="OurNewPartnersLeftCard-left">
                  <img src={quantum} alt="quantum_cloud"></img>
                </div>
                <div className="OurNewPartnersLeftCard-right">
                  <div className="OurNewPartnersLeftCard-title">
                    Quantum Cloud
                  </div>
                  <div className="OurNewPartnersLeftCard-subtitle">
                    <p> Our representative in KSA</p>
                  </div>
                  <div className="OurNewPartnersLeftCard-location">
                    <span>
                      <svg
                        width="17"
                        height="22"
                        viewBox="0 0 17 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 5C7.75832 5 7.0333 5.21993 6.41661 5.63199C5.79993 6.04404 5.31928 6.62971 5.03545 7.31494C4.75162 8.00016 4.67736 8.75416 4.82205 9.48159C4.96675 10.209 5.3239 10.8772 5.84835 11.4017C6.3728 11.9261 7.04098 12.2833 7.76841 12.4279C8.49584 12.5726 9.24984 12.4984 9.93506 12.2145C10.6203 11.9307 11.206 11.4501 11.618 10.8334C12.0301 10.2167 12.25 9.49168 12.25 8.75C12.25 7.75544 11.8549 6.80161 11.1517 6.09835C10.4484 5.39509 9.49456 5 8.5 5ZM8.5 11C8.05499 11 7.61998 10.868 7.24997 10.6208C6.87996 10.3736 6.59157 10.0222 6.42127 9.61104C6.25097 9.1999 6.20642 8.7475 6.29323 8.31105C6.38005 7.87459 6.59434 7.47368 6.90901 7.15901C7.22368 6.84434 7.62459 6.63005 8.06105 6.54323C8.4975 6.45642 8.9499 6.50097 9.36104 6.67127C9.77217 6.84157 10.1236 7.12996 10.3708 7.49997C10.618 7.86998 10.75 8.30499 10.75 8.75C10.75 9.34674 10.5129 9.91903 10.091 10.341C9.66903 10.7629 9.09674 11 8.5 11ZM8.5 0.5C6.31273 0.502481 4.21575 1.37247 2.66911 2.91911C1.12247 4.46575 0.252481 6.56273 0.25 8.75C0.25 11.6938 1.61031 14.8138 4.1875 17.7734C5.34552 19.1108 6.64886 20.3151 8.07344 21.3641C8.19954 21.4524 8.34978 21.4998 8.50375 21.4998C8.65772 21.4998 8.80796 21.4524 8.93406 21.3641C10.356 20.3147 11.6568 19.1104 12.8125 17.7734C15.3859 14.8138 16.75 11.6938 16.75 8.75C16.7475 6.56273 15.8775 4.46575 14.3309 2.91911C12.7843 1.37247 10.6873 0.502481 8.5 0.5ZM8.5 19.8125C6.95031 18.5938 1.75 14.1172 1.75 8.75C1.75 6.95979 2.46116 5.2429 3.72703 3.97703C4.9929 2.71116 6.70979 2 8.5 2C10.2902 2 12.0071 2.71116 13.273 3.97703C14.5388 5.2429 15.25 6.95979 15.25 8.75C15.25 14.1153 10.0497 18.5938 8.5 19.8125Z"
                          fill="#D9D9D9"
                        />
                      </svg>
                    </span>
                    <p>Jeddah, AlTahliah St.Saudi Arabia</p>
                  </div>
                  <div className="OurNewPartnersLeftCard-desc">
                    <p>
                      Quantum Cloud consultancy company to help healthcare
                      facilities to overcome their obstacles.
                    </p>
                  </div>
                  <div className="OurNewPartnersLeftCard-btn">
                    <a href="https://quantumcloudsa.com">View More</a>
                  </div>
                </div>
              </div>
            </div> */}

            {/* kadrimedtech */}

            <div className="OurNewPartnersRightCards">
              <div className="OurNewPartnersRightCard-body newPartnersCard-3">
                <div className="OurNewPartnersRightCard-img_top">
                  <img src={kadrimedtechBg} alt="card_image"></img>
                </div>
                <div className="OurNewPartnersRightCard-inner--content">
                  <div className="OurNewPartnersRightCard-inner_img">
                    <img src={kadrimedtech} alt="card_image"></img>
                  </div>
                  <div className="OurNewPartnersRightCard-text--content">
                    <div className="OurNewPartnersRightCard-title">
                      KadriMedTech
                    </div>
                    <div className="OurNewPartnersRightCard-subtitle">
                      Our Medical Subsidiary
                    </div>
                    <div className="OurNewPartnersRightCard-location">
                      <span>
                        <svg
                          width="17"
                          height="22"
                          viewBox="0 0 17 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 5C7.75832 5 7.0333 5.21993 6.41661 5.63199C5.79993 6.04404 5.31928 6.62971 5.03545 7.31494C4.75162 8.00016 4.67736 8.75416 4.82205 9.48159C4.96675 10.209 5.3239 10.8772 5.84835 11.4017C6.3728 11.9261 7.04098 12.2833 7.76841 12.4279C8.49584 12.5726 9.24984 12.4984 9.93506 12.2145C10.6203 11.9307 11.206 11.4501 11.618 10.8334C12.0301 10.2167 12.25 9.49168 12.25 8.75C12.25 7.75544 11.8549 6.80161 11.1517 6.09835C10.4484 5.39509 9.49456 5 8.5 5ZM8.5 11C8.05499 11 7.61998 10.868 7.24997 10.6208C6.87996 10.3736 6.59157 10.0222 6.42127 9.61104C6.25097 9.1999 6.20642 8.7475 6.29323 8.31105C6.38005 7.87459 6.59434 7.47368 6.90901 7.15901C7.22368 6.84434 7.62459 6.63005 8.06105 6.54323C8.4975 6.45642 8.9499 6.50097 9.36104 6.67127C9.77217 6.84157 10.1236 7.12996 10.3708 7.49997C10.618 7.86998 10.75 8.30499 10.75 8.75C10.75 9.34674 10.5129 9.91903 10.091 10.341C9.66903 10.7629 9.09674 11 8.5 11ZM8.5 0.5C6.31273 0.502481 4.21575 1.37247 2.66911 2.91911C1.12247 4.46575 0.252481 6.56273 0.25 8.75C0.25 11.6938 1.61031 14.8138 4.1875 17.7734C5.34552 19.1108 6.64886 20.3151 8.07344 21.3641C8.19954 21.4524 8.34978 21.4998 8.50375 21.4998C8.65772 21.4998 8.80796 21.4524 8.93406 21.3641C10.356 20.3147 11.6568 19.1104 12.8125 17.7734C15.3859 14.8138 16.75 11.6938 16.75 8.75C16.7475 6.56273 15.8775 4.46575 14.3309 2.91911C12.7843 1.37247 10.6873 0.502481 8.5 0.5ZM8.5 19.8125C6.95031 18.5938 1.75 14.1172 1.75 8.75C1.75 6.95979 2.46116 5.2429 3.72703 3.97703C4.9929 2.71116 6.70979 2 8.5 2C10.2902 2 12.0071 2.71116 13.273 3.97703C14.5388 5.2429 15.25 6.95979 15.25 8.75C15.25 14.1153 10.0497 18.5938 8.5 19.8125Z"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </span>
                      <p>Stockholm, Sweden</p>
                    </div>
                    <div className="OurNewPartnersRightCard-desc">
                      <p>
                        {" "}
                        KMT Consulting offers specialist B2B consulting services
                        and networking opportunities for Life Science and
                        healthcare companies
                      </p>
                    </div>
                    <div className="OurNewPartnersRightCard-btn">
                      <a href="https://med.kadritech.se">View More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="OurNewPartnersRightCards">
              <div className="OurNewPartnersRightCard-body newPartnersCard-3">
                <div className="OurNewPartnersRightCard-img_top">
                  <img src={dataholicbg} alt="card_image"></img>
                </div>
                <div className="OurNewPartnersRightCard-inner--content">
                  <div className="OurNewPartnersRightCard-inner_img">
                    <img src={dataholic} alt="card_image"></img>
                  </div>
                  <div className="OurNewPartnersRightCard-text--content">
                    <div className="OurNewPartnersRightCard-title">
                      Dataholic
                    </div>
                    <div className="OurNewPartnersRightCard-subtitle">
                      Google Parnter
                    </div>
                    <div className="OurNewPartnersRightCard-location">
                      <span>
                        <svg
                          width="17"
                          height="22"
                          viewBox="0 0 17 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 5C7.75832 5 7.0333 5.21993 6.41661 5.63199C5.79993 6.04404 5.31928 6.62971 5.03545 7.31494C4.75162 8.00016 4.67736 8.75416 4.82205 9.48159C4.96675 10.209 5.3239 10.8772 5.84835 11.4017C6.3728 11.9261 7.04098 12.2833 7.76841 12.4279C8.49584 12.5726 9.24984 12.4984 9.93506 12.2145C10.6203 11.9307 11.206 11.4501 11.618 10.8334C12.0301 10.2167 12.25 9.49168 12.25 8.75C12.25 7.75544 11.8549 6.80161 11.1517 6.09835C10.4484 5.39509 9.49456 5 8.5 5ZM8.5 11C8.05499 11 7.61998 10.868 7.24997 10.6208C6.87996 10.3736 6.59157 10.0222 6.42127 9.61104C6.25097 9.1999 6.20642 8.7475 6.29323 8.31105C6.38005 7.87459 6.59434 7.47368 6.90901 7.15901C7.22368 6.84434 7.62459 6.63005 8.06105 6.54323C8.4975 6.45642 8.9499 6.50097 9.36104 6.67127C9.77217 6.84157 10.1236 7.12996 10.3708 7.49997C10.618 7.86998 10.75 8.30499 10.75 8.75C10.75 9.34674 10.5129 9.91903 10.091 10.341C9.66903 10.7629 9.09674 11 8.5 11ZM8.5 0.5C6.31273 0.502481 4.21575 1.37247 2.66911 2.91911C1.12247 4.46575 0.252481 6.56273 0.25 8.75C0.25 11.6938 1.61031 14.8138 4.1875 17.7734C5.34552 19.1108 6.64886 20.3151 8.07344 21.3641C8.19954 21.4524 8.34978 21.4998 8.50375 21.4998C8.65772 21.4998 8.80796 21.4524 8.93406 21.3641C10.356 20.3147 11.6568 19.1104 12.8125 17.7734C15.3859 14.8138 16.75 11.6938 16.75 8.75C16.7475 6.56273 15.8775 4.46575 14.3309 2.91911C12.7843 1.37247 10.6873 0.502481 8.5 0.5ZM8.5 19.8125C6.95031 18.5938 1.75 14.1172 1.75 8.75C1.75 6.95979 2.46116 5.2429 3.72703 3.97703C4.9929 2.71116 6.70979 2 8.5 2C10.2902 2 12.0071 2.71116 13.273 3.97703C14.5388 5.2429 15.25 6.95979 15.25 8.75C15.25 14.1153 10.0497 18.5938 8.5 19.8125Z"
                            fill="#D9D9D9"
                          />
                        </svg>
                      </span>
                      <p>Dubai, UAE</p>
                    </div>
                    <div className="OurNewPartnersRightCard-desc">
                      <p>Creating the Future of Modern Workspace</p>
                    </div>
                    <div className="OurNewPartnersRightCard-btn">
                      <a href="https://www.dataholic.info">View More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="OurNewPartnersLeftCard"></div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurNewPartners;
