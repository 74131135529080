import React, { useState } from "react";
import "./testimonialSection.scss";
import RightArrow from "../../assets/project-imgs/Arrow/right.svg";
import LeftArrow from "../../assets/project-imgs/Arrow/left.svg";
import { motion } from "framer-motion/dist/framer-motion";

const MAX_VISIBILITY = 2;

const TestimonialCarousel = ({ children }) => {
  const [active, setActive] = useState(0);
  const count = React.Children.count(children);

  return (
    <div className="carousel">
      {active > 0 && (
        <button className="nav left" onClick={() => setActive((i) => i - 1)}>
          <motion.img
            id="TestimonialLeftArrow"
            src={LeftArrow}
            alt=""
            initial={{ opacity: 0.5 }}
            whileHover={{ opacity: 1 }}
          />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div key={i}
          className="card-container"
          style={{
            "--active": i === active ? 1 : 0,
            "--offset": (active - i) / 3,
            "--direction": Math.sign(active - i),
            "--abs-offset": Math.abs(active - i) / 3,
            "pointerEvents": active === i ? "auto" : "none",
            opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
            display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
            objectFit: "cover",
            borderRadius: 5,
          }}
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button className="nav right" onClick={() => setActive((i) => i + 1)}>
          <motion.img
            id="TestimonialRightArrow"
            src={RightArrow}
            alt=""
            initial={{ opacity: 0.5 }}
            whileHover={{ opacity: 1 }}
          />
        </button>
      )}
    </div>
  );
};

export default TestimonialCarousel;
