import "./ticketCarousel.scss";
import { useEffect, useState } from "react";
import Timer from "../../misc/timer";
import { useStateValue } from "../../redux/StateProvider";

function TicketCarousel() {
  const [mem, setMem] = useState({
    img1: "ticket1",
    img2: "ticket2",
    img3: "ticket3",
    img4: "ticket4",
  });
  const [progress, setProgress] = useState(1);
  const [timer, setTimer] = useState({});
  const [status, setStatus] = useState({ progress: 1, state: "original" });
  const [mobile, setMobile] = useState(false);
  const [{ language }] = useStateValue();

  const performAnimation = (ticket) => {
    var location = "";
    Object.entries(mem).map((entry) => {
      if (entry[1] === ticket) {
        location = entry[0];
      }
      return location;
    });

    if (location === "img2") {
      document.getElementById(mem.img1).style.left = "68.5%";
      document.getElementById(mem.img1).style.marginTop = "2.35%";
      document.getElementById(mem.img1).style.transform =
        "scale3d(0.64,0.61,1)";
      document.getElementById(mem.img1).style.zIndex = 1;

      document.getElementById(mem.img2).style.transform = !mobile
        ? "scale3d(1,1,1)"
        : "scale3d(1.19,1.25,1)";
      document.getElementById(mem.img2).style.left = !mobile
        ? "30.7%"
        : "30.75%";
      document.getElementById(mem.img2).style.marginTop = !mobile
        ? "14%"
        : "15%";
      document.getElementById(mem.img2).style.zIndex = 100;

      document.getElementById(mem.img3).style.left = "-6.9%";
      document.getElementById(mem.img3).style.marginTop = "2.35%";
      document.getElementById(mem.img3).style.transform =
        "scale3d(0.64,0.61,1)";
      document.getElementById(mem.img3).style.zIndex = 1;

      document.getElementById(mem.img4).style.left = "30.85%";
      document.getElementById(mem.img4).style.marginTop = "-11.24%";
      document.getElementById(mem.img4).style.zIndex = 1;

      let memC = mem;
      memC.img2 = mem.img3;
      memC.img3 = mem.img4;
      memC.img4 = mem.img1;

      memC.img1 = ticket;
      setMem(memC);

      if (progress > 1) {
        setProgress(progress - 1);
        setStatus({ progress: progress - 1, state: "New" });
      }
      if (progress === 1) {
        setProgress(4);
        setStatus({ progress: 4, state: "New" });
      }
    }
    if (location === "img3") {
      document.getElementById(mem.img1).style.left = "30.85%";
      document.getElementById(mem.img1).style.marginTop = "-11.24%";
      document.getElementById(mem.img1).style.transform =
        "scale3d(0.64,0.61,1)";
      document.getElementById(mem.img1).style.zIndex = 1;

      document.getElementById(mem.img3).style.transform = !mobile
        ? "scale3d(1,1,1)"
        : "scale3d(1.19,1.25,1)";
      document.getElementById(mem.img3).style.left = !mobile
        ? "30.7%"
        : "30.75%";
      document.getElementById(mem.img3).style.marginTop = !mobile
        ? "14%"
        : "15%";
      document.getElementById(mem.img3).style.zIndex = 3;

      let memC = mem;
      memC.img3 = mem.img1;
      memC.img1 = ticket;

      setMem(memC);
      if (progress < 4) {
        setProgress(progress + 2);
        setStatus({ progress: progress + 2, state: "New" });
      }
      if (progress === 3) {
        setProgress(1);
        setStatus({ progress: 1, state: "New" });
      }
      if (progress === 4) {
        setProgress(2);
        setStatus({ progress: 2, state: "New" });
      }
    }
    if (location === "img4") {
      document.getElementById(mem.img1).style.left = "-6.9%";
      document.getElementById(mem.img1).style.marginTop = "2.35%";
      document.getElementById(mem.img1).style.transform =
        "scale3d(0.64,0.61,1)";
      document.getElementById(mem.img1).style.zIndex = 1;

      document.getElementById(mem.img2).style.left = "30.85%";
      document.getElementById(mem.img2).style.marginTop = "-11.24%";
      document.getElementById(mem.img2).style.zIndex = 1;

      document.getElementById(mem.img3).style.left = "68.5%";
      document.getElementById(mem.img3).style.marginTop = "2.35%";
      document.getElementById(mem.img3).style.zIndex = 1;

      document.getElementById(mem.img4).style.transform = !mobile
        ? "scale3d(1,1,1)"
        : "scale3d(1.19,1.25,1)";
      document.getElementById(mem.img4).style.left = !mobile
        ? "30.7%"
        : "30.75%";
      document.getElementById(mem.img4).style.marginTop = !mobile
        ? "14%"
        : "15%";
      document.getElementById(mem.img4).style.zIndex = 100;

      let memC = mem;
      memC.img4 = mem.img3;
      memC.img3 = mem.img2;
      memC.img2 = mem.img1;
      memC.img1 = ticket;

      setMem(memC);

      if (progress < 4) {
        setProgress(progress + 1);
        setStatus({ progress: progress + 1, state: "New" });
      }
      if (progress === 4) {
        setProgress(1);
        setStatus({ progress: 1, state: "New" });
      }
    }
  };

  useEffect(() => {
    let memC = mem;
    let progressA = status.progress;
    let mobile = false;

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 550) {
        setMobile(true);
        mobile = true;
      } else {
        setMobile(false);
        mobile = false;
      }
    });

    if (window.innerWidth <= 550) {
      setMobile(true);
      mobile = true;
    } else {
      setMobile(false);
      mobile = false;
    }

    if (status.state !== "original") {
      timer.stop();
    }
    let timer2 = new Timer(() => {
      if (window.innerWidth <= 550) {
        mobile = true;
      } else {
        mobile = false;
      }

      document.getElementById(memC.img1).style.left = "-6.9%";
      document.getElementById(memC.img1).style.marginTop = "2.35%";
      document.getElementById(memC.img1).style.transform =
        "scale3d(0.64,0.61,1)";
      document.getElementById(memC.img1).style.zIndex = 1;

      document.getElementById(memC.img2).style.left = "30.85%";
      document.getElementById(memC.img2).style.marginTop = "-11.24%";
      document.getElementById(memC.img2).style.zIndex = 1;

      document.getElementById(memC.img3).style.left = "68.5%";
      document.getElementById(memC.img3).style.marginTop = "2.35%";
      document.getElementById(memC.img3).style.zIndex = 1;

      document.getElementById(memC.img4).style.transform = !mobile
        ? "scale3d(1,1,1)"
        : "scale3d(1.19,1.25,1)";
      document.getElementById(memC.img4).style.left = !mobile
        ? "30.7%"
        : "30.75%";
      document.getElementById(memC.img4).style.marginTop = !mobile
        ? "14%"
        : "15%";
      document.getElementById(memC.img4).style.zIndex = 100;

      let memD = {};
      memD.img4 = memC.img3;
      memD.img3 = memC.img2;
      memD.img2 = memC.img1;
      memD.img1 = memC.img4;

      setMem(memD);

      memC = memD;

      if (progressA < 4) {
        progressA++;
        setProgress(progressA);
        return;
      }

      if (progressA === 4) {
        progressA = 1;
        setProgress(1);
      }
    }, [6000]);
    setTimer(timer2);
  }, [status]);

  const stopTimer = (hovering) => {
    if (hovering === true) {
      timer.stop();
    } else {
      timer.start();
    }
  };
  return (
    <div className="ticketContainer">
      <div
        onMouseEnter={() => {
          stopTimer(true);
        }}
        onMouseLeave={() => {
          stopTimer(false);
        }}
        onClick={() => {
          performAnimation("ticket2");
        }}
        className="ticket"
        id="ticket2"
      >
        <div className="ticket-img-holder">
          <img
            className="ticketImg"
            src="https://images.pexels.com/photos/9996617/pexels-photo-9996617.png?auto=compress&cs=tinysrgb&dpr=1&w=1500"
            alt=""
          ></img>
        </div>
        {language === "English" ? (
          <div className="ticketBottom">
            <span className="ticketBottom-largeTitle">Graphic Design</span>
            <p className="ticketBottom-largeDesc">
              Stay up-to-date with designs that are trendy, relevant, and
              suitable for your business and customers’ needs.
            </p>
          </div>
        ) : language === "Swedish" ? (
          <div className="ticketBottom">
            <span className="ticketBottom-largeTitle">Grafisk Design</span>
            <p className="ticketBottom-largeDesc">
              Håll dig uppdaterad med design som är trendig, relevant och passar
              ditt företag och kundernas behov.
            </p>
          </div>
        ) : null}
      </div>

      <div
        onMouseEnter={() => {
          stopTimer(true);
        }}
        onMouseLeave={() => {
          stopTimer(false);
        }}
        onClick={() => {
          performAnimation("ticket3");
        }}
        className="ticket"
        id="ticket3"
      >
        <div className="ticket-img-holder">
          <img
            style={{ minWidth: "100%" }}
            className="ticketImg"
            src="https://images.pexels.com/photos/9996615/pexels-photo-9996615.png?auto=compress&cs=tinysrgb&dpr=1&w=1400"
            alt=""
          ></img>
        </div>
        {language === "English" ? (
          <div className="ticketBottom">
            <span className="ticketBottom-largeTitle">UI & UX Design</span>
            <p className="ticketBottom-largeDesc">
              Leverage our expertise to create exquisite product designs and
              build lovable user interfaces and improve users experience across
              your digital platforms.
            </p>
          </div>
        ) : language === "Swedish" ? (
          <div className="ticketBottom">
            <span className="ticketBottom-largeTitle">UI & UX Design</span>
            <p className="ticketBottom-largeDesc">
              Utnyttja vår expertis för att skapa utsökt produktdesign och
              förbättra användar upplevelsen för dina digitala plattformar.
            </p>
          </div>
        ) : null}
      </div>

      <div
        onMouseEnter={() => {
          stopTimer(true);
        }}
        onMouseLeave={() => {
          stopTimer(false);
        }}
        onClick={() => {
          performAnimation("ticket4");
        }}
        className="ticket"
        id="ticket4"
      >
        <div className="ticket-img-holder">
          <img
            className="ticketImg"
            src="https://images.pexels.com/photos/9996618/pexels-photo-9996618.png?auto=compress&cs=tinysrgb&dpr=1&w=1500"
            alt=""
          ></img>
        </div>
        {language === "English" ? (
          <div className="ticketBottom">
            <span className="ticketBottom-largeTitle">Web Develoment</span>
            <p className="ticketBottom-largeDesc">
              Combine your ideas with our expertise to develop fully responsive
              websites with custom features that make doing business easy for
              you.
            </p>
          </div>
        ) : language === "Swedish" ? (
          <div className="ticketBottom">
            <span className="ticketBottom-largeTitle">Web Utveckling</span>
            <p className="ticketBottom-largeDesc">
              Kombinera dina idéer med vår expertis för att utvecklas fullt
              lyhörd webbplatser med anpassade funktioner som gör det enkelt för
              ditt företag.
            </p>
          </div>
        ) : null}
      </div>

      <div
        onMouseEnter={() => {
          stopTimer(true);
        }}
        onMouseLeave={() => {
          stopTimer(false);
        }}
        onClick={() => {
          performAnimation("ticket1");
        }}
        className="ticket"
        id="ticket1"
      >
        <div className="ticket-img-holder">
          <img
            className="ticketImg"
            src="https://images.pexels.com/photos/9996595/pexels-photo-9996595.png?auto=compress&cs=tinysrgb&dpr=1&w=1200"
            alt=""
          ></img>
        </div>
        {language === "English" ? (
          <div className="ticketBottom">
            <span className="ticketBottom-largeTitle">
              Mobile App Develoment
            </span>
            <p className="ticketBottom-largeDesc">
              Develop user-friendly apps that can be deployed on all devices.
              Integrate custom mobile app features to your business and
              customers’ needs.
            </p>
          </div>
        ) : language === "Swedish" ? (
          <div className="ticketBottom">
            <span className="ticketBottom-largeTitle">
              Mobile App Utveckling
            </span>
            <p className="ticketBottom-largeDesc">
              Utveckla användarvänliga appar som kan distribueras på alla
              enheter. Integrera anpassade mobilapps funktioner för ditt
              företag.
            </p>
          </div>
        ) : null}
      </div>

      <div className="progressViewer">
        <span
          className="progressIndicator"
          style={{ backgroundColor: progress === 1 ? "#FFFFFF" : "#666D86" }}
        />
        <span
          className="progressIndicator"
          style={{ backgroundColor: progress === 2 ? "#FFFFFF" : "#666D86" }}
        />
        <span
          className="progressIndicator"
          style={{ backgroundColor: progress === 3 ? "#FFFFFF" : "#666D86" }}
        />
        <span
          className="progressIndicator"
          style={{ backgroundColor: progress === 4 ? "#FFFFFF" : "#666D86" }}
        />
      </div>
    </div>
  );
}

export default TicketCarousel;
