import React from "react";
import ceo from "../../assets/aboutUs/ceo rect.png";
import lawyer from "../../assets/aboutUs/lawyer.png";
import "./AboutUsSub.scss";
const AboutUsSub = () => {
  return (
    <div className="AboutUsSubSection">
      <div className="AboutUsSubSection_container">
        <div className="AboutUsSubSection__left">
          <div className="AboutUsSubSection__content">
            <div className="AboutUsSubSection__img__box">
              <img src={ceo} alt="about_us_sub_section_image"></img>
            </div>
            <div className="AboutUsSubSection__text">
              <div className="AboutUsSubSection__text-primary">
                <h2>Adam Kadri</h2>
              </div>
              <div className="AboutUsSubSection__text-secondary">
                <p>CEO & Founder | Project Manager</p>
              </div>
              <div className="AboutUsSubSection__text-desc">
                <a href="mailto:adam@kadritech.se">adam@kadritech.se</a>
              </div>
            </div>
          </div>
        </div>
        {/* right */}
        <div className="AboutUsSubSection__right">
          <div className="AboutUsSubSection__content">
            <div className="AboutUsSubSection__img__box">
              <img src={lawyer} alt="about_us_sub_section_image"></img>
            </div>
            <div className="AboutUsSubSection__text">
              <div className="AboutUsSubSection__text-primary--top">
                <h2>Legal Person</h2>
              </div>
              <div className="AboutUsSubSection__text-primary">
                <h2>Anna Krisitna Malke</h2>
              </div>
              <div className="AboutUsSubSection__text-secondary">
                <p>Lawyer and public relations Manager</p>
              </div>
              <div className="AboutUsSubSection__text-desc">
                <a href="mailto:anna.kristina.malke@kadritech.se">anna.kristina.malke@kadritech.se</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSub;
