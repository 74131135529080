
//redux initial state
export const initialState={
    language:'English',
};


//redux reducer
const reducer=(state,action)=>{
    switch(action.type)
    {
        case "language":
            return{
                ...state,
                language:action.language,
            }
        default:
            return state;
    }
}
export default reducer;