import React, { useEffect, useState } from "react";
import TeamMember1 from "../../../assets/aboutUs/teamMember-1.png";
import TeamMember2 from "../../../assets/aboutUs/teamMember-2.png";
import TeamMember3 from "../../../assets/aboutUs/teamMember-3.png";
import TeamMember4 from "../../../assets/aboutUs/teamMember-4.png";
import TeamMember5 from "../../../assets/aboutUs/teamMember-5.png";
import TeamMember6 from "../../../assets/aboutUs/teamMember-6.png";
import "./KadriTechsolarAnimation.scss";
import Orbit from "./Orbit";

const teamMember = [
  {
    id: 1,
    img: TeamMember1,
    title: "Muin Ahamed",
    desc: "Frontend Developer",
    animationDuration: "10s",
    size: "350px",
    borderStyle: undefined,
  },
  {
    id: 2,
    img: TeamMember2,
    title: "Sajib Uzzaman",
    desc: "Full-Stack Developer",
    animationDuration: "11s",
    size: "450px",
    borderStyle: undefined,
  },
  {
    id: 2,
    img: TeamMember3,
    title: "Sohidul Islam",
    desc: "Frontend developer",
    animationDuration: "12s",
    size: "550px",
    borderStyle: undefined,
  },
  // {
  //   id: 3,
  //   img: TeamMember4,
  //   title: "Nazib Talukder",
  //   desc: "Frontend developer",
  //   animationDuration: "13s",
  //   size: "650px",
  //   borderStyle:undefined,
  // },

  {
    id: 5,
    img: TeamMember6,
    title: "Vikram Singh",
    desc: "Sr. UI & UX Designer",
    animationDuration: "15s",
    size: "750px",
    borderStyle: undefined,
  },
];

function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

function KadriTechSolar() {
  const [queue, setQueue] = useState([]);
  const [currentService, setCurrentService] = useState(1);
  const [isShifted, setIsShifted] = useState(false);

  useEffect(() => {
    setQueue((prev) => {
      if (prev.length > 0) return prev;
      return teamMember.map((member, index) => index + 1);
    });
  }, []);

  function enqueue(element) {
    setQueue((prevQueue) => {
      if (!isInArray(element, prevQueue)) {
        return [...prevQueue, element];
      } else {
        return [...prevQueue];
      }
    });
    return;
  }

  function processQueue() {
    const [element, ...rest] = queue;
    setTimeout(() => {
      setQueue(() => {
        if (rest.length > 0) {
          return rest;
        }
        setCurrentService(1);
        return teamMember.map((member, index) => index + 1);
      });
    }, 3000);
  }

  return (
    <div className="solarSystemContainer">
      {teamMember.map((member, i) => (
        <Orbit
          key={i + 1}
          id={i + 1}
          currentService={currentService}
          borderStyle={member?.borderStyle}
          queueSize={teamMember.length}
          isShifted={isShifted}
          setCurrentService={setCurrentService}
          setIsShifted={setIsShifted}
          queue={queue}
          enqueue={enqueue}
          processQueue={processQueue}
          img={member?.img}
          title={member?.title}
          desc={member?.desc}
          animationDuration={member?.animationDuration}
          size={member?.size}
        />
      ))}
    </div>
  );
}

export default KadriTechSolar;
