import { Suspense, lazy, useEffect, useRef, useState } from "react";
import "./App.scss";
import "./variables.scss";
import { motion } from "framer-motion/dist/framer-motion";
import ProjectCarousel2 from "./components/project-carousel/projectCarousel2";
import Header from "./components/header/Header";
import HomeSection from "./components/homesection/HomeSection";
import AboutUSSection from "./components/who-we-are/AboutUsSection";
import AboutUsSub from "./components/who-we-are/AboutUsSub";
import TestimonialSection from "./components/testimonials/TestimonialSection";
import ContactUsForm from "./components/contactus/ContactUsForm";
// import Video from "./components/video/Video";
import observer from "./misc/observer";
import smoothscroll from "smoothscroll-polyfill";
import Social from "./components/social-media/social";
import { useStateValue } from "./redux/StateProvider";
import TeamMembers from "./components/who-we-are/TeamMembers";
import OurNewPartners from "./components/who-we-are/OurNewPartners";
import NewServicesSection from "./components/services-section/NewServicesSection";
import NewFooter from "./components/footer/NewFooter";
import TeamMember2 from "./components/who-we-are/TeamMembers2";

const Video = lazy(() => import("./components/video/Video"));

window.__forceSmoothScrollPolyfill__ = true;

function App() {
  useEffect(() => {
    observer.observe(document.querySelector(".ProjectCarousel"));
    observer.observe(document.querySelector(".lg-device-service-section"));
    observer.observe(document.querySelector(".service-1"));
    observer.observe(document.querySelector(".service-2"));
    observer.observe(document.querySelector(".service-3"));
    observer.observe(document.querySelector(".service-4"));
    observer.observe(document.querySelector(".service-5"));
    observer.observe(document.querySelector(".newTeamMembers"));
    observer.observe(document.querySelector(".AboutUsSectionMain"));
    observer.observe(document.querySelector(".AboutUsSubSection"));
    observer.observe(document.querySelector(".lg-OurNewPartnersSection"));
    // observer.observe(document.querySelector(".newPartnersCard-1"));
    // observer.observe(document.querySelector(".newPartnersCard-2"));
    observer.observe(document.querySelector(".newPartnersCard-3"));
    // observer.observe(document.querySelector(".TeamMembers"));
    observer.observe(document.querySelector(".TestimonialSection"));
    observer.observe(document.querySelector(".ContactUsSection"));
    observer.observe(document.querySelector(".social"));
    smoothscroll.polyfill();

    // opacity 1
    // document.querySelector(".ProjectCarousel").style.opacity = "1";
    // document.querySelector(".lg-device-service-section").style.opacity = "1";
    // document.querySelector(".service-1").style.opacity = "1";
    // document.querySelector(".service-2").style.opacity = "1";
    // document.querySelector(".service-3").style.opacity = "1";
    // document.querySelector(".service-4").style.opacity = "1";
    // document.querySelector(".service-5").style.opacity = "1";

    // document.querySelector(".newTeamMembers").style.opacity = "1";
    // document.querySelector(".AboutUsSectionMain").style.opacity = "1";
    // document.querySelector(".AboutUsSubSection").style.opacity = "1";
    // document.querySelector(".lg-OurNewPartnersSection").style.opacity = "1";
    // document.querySelector(".newPartnersCard-1").style.opacity = "1";
    // document.querySelector(".newPartnersCard-2").style.opacity = "1";
    // document.querySelector(".newPartnersCard-3").style.opacity = "1";
    // document.querySelector(".TeamMembers").style.opacity = "1";
    // document.querySelector(".TestimonialSection").style.opacity = "1";
    // document.querySelector(".ContactUsSection").style.opacity = "1";
    // document.querySelector(".social").style.opacity = "1";
  }, []);

  //used to dispatch state to other components
  const [{}, dispatch] = useStateValue();
  //language state
  const [{ language }] = useStateValue();

  const changeLanguage = () => {
    if (language === "Swedish") {
      dispatch({
        type: "language",
        language: "English",
      });
    } else {
      dispatch({
        type: "language",
        language: "Swedish",
      });
    }
  };

  function scrollToTop() {
    document
      .getElementsByClassName("App")[0]
      .scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const onScroll = (e) => {
    const currentScrollY = e.target.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    prevScrollY.current = currentScrollY;

    if (currentScrollY > 300) {
      document.getElementById("Lang1").className = "Lang1";
      document.getElementById("Lang1").style.display = "flex";
    }

    if (currentScrollY < 300) {
      document.getElementById("Lang1").className = "Lang2";
    }
  };

  return (
    <div className="App" onScroll={onScroll}>
      <motion.div
        onClick={changeLanguage}
        id="Lang"
        className="ChangeLanguageIcon"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        initial={{ opacity: 0.5 }}
        whileHover={{ opacity: 1 }}
      >
        {language === "Swedish" ? "EN" : "SE"}
      </motion.div>
      <motion.div
        onClick={scrollToTop}
        id="Lang1"
        style={{ display: "none" }}
        initial={{ opacity: 0.5 }}
        whileHover={{ opacity: 1 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42.3"
          height="42.3"
          viewBox="0 0 42.3 42.3"
        >
          <g transform="translate(-1551.394 -4.995)">
            <path
              d="M21.15,0A21.15,21.15,0,1,1,0,21.15,21.15,21.15,0,0,1,21.15,0Z"
              transform="translate(1551.394 4.995)"
              fill="#666d86"
            />
            <path
              d="M15.8,14.562l7.264,7.27A1.373,1.373,0,0,0,25,19.887L16.769,11.65a1.37,1.37,0,0,0-1.893-.04L6.588,19.881a1.373,1.373,0,0,0,1.939,1.945Z"
              transform="translate(1556.594 9.2)"
            />
          </g>
        </svg>
      </motion.div>

      <Suspense fallback={<h1>Loading...</h1>}>
        <Video />
      </Suspense>

      <Header />

      <div className="App-inner">
        <HomeSection />

        {language === "English" ? (
          <h3 className="section-title" id="st-head">
            What Services We Offer
          </h3>
        ) : language === "Swedish" ? (
          <h3 className="section-title" id="st-head">
            Vilka tjänster vi erbjuder
          </h3>
        ) : null}

        <NewServicesSection />
      </div>

      <ProjectCarousel2 />

      <div className="App-inner">
        <Social />
        {language === "English" ? (
          <span className="section-title" id="aboutUsTitle">
            Who We Are
          </span>
        ) : language === "Swedish" ? (
          <span className="section-title" id="aboutUsTitle">
            Vilka vi är
          </span>
        ) : null}

        <AboutUSSection />
        <AboutUsSub />

        <OurNewPartners />
        {/* <OurPartners /> */}

        {language === "English" ? (
          <h3 className="section-title">Meet our creative team members</h3>
        ) : language === "Swedish" ? (
          <h3 className="section-title">Möt våra kreativa teammedlemmar</h3>
        ) : null}
        {/* <TeamMembers /> */}
        <TeamMember2 />

        <TestimonialSection />

        <ContactUsForm />

        {/* <Footer /> */}
      </div>
      <NewFooter />
    </div>
  );
}

export default App;
