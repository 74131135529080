import React, { useState } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import "./projectCarousel.scss";
import A2zLogo from "../../assets/project-imgs/A2z-logo.svg";
import A2zWeb from "../../assets/project-imgs/A2z-web.png";
import A2zMobile from "../../assets/project-imgs/A2z-mobile.png";
import BeautyClubLogo from "../../assets/project-imgs/beautyClub-logo.svg";
import BeautyClubWeb from "../../assets/project-imgs/beautyClub-web.png";
import BeautyClubApp1 from "../../assets/project-imgs/beautyClub-app1.png";
import BeautyClubApp2 from "../../assets/project-imgs/beautyClub-app2.png";
import BeautyClubWeb1 from "../../assets/project-imgs/beautyClub-web1.png";
import LyxaLogo from "../../assets/project-imgs/Lyxa-logo.svg";
import LyxaWeb from "../../assets/project-imgs/Lyxa-web.png";
import LyxaApp1 from "../../assets/project-imgs/Lyxa-app1.png";
import LyxaApp2 from "../../assets/project-imgs/Lyxa-app2.png";
import LyxaWeb1 from "../../assets/project-imgs/Lyxa-web1.png";
import PlatinumLogo from "../../assets/project-imgs/Platinum-logo.svg";
import PlatinumWeb from "../../assets/project-imgs/Platinum-web.png";
import PlatinumMobile from "../../assets/project-imgs/Platinum-mobile.png";
import MBEBottom from "../../assets/project-imgs/MBE-bottom.png";
import MBETop from "../../assets/project-imgs/MBE-top.png";
import MBELogo from "../../assets/project-imgs/MBE-Logo.svg";
import NailzyBottom from "../../assets/project-imgs/Nailzy-bottom.png";
import NailzyTop from "../../assets/project-imgs/Nailzy-top.png";
import NailzyLogo from "../../assets/project-imgs/Nailzy-logo.svg";
import Logo from "../../assets/project-imgs/logo/logo.svg";
import LogosTop1 from "../../assets/project-imgs/logo/logo-top1.png";
import LogosTop2 from "../../assets/project-imgs/logo/logo-top2.png";
import LogosBottom1 from "../../assets/project-imgs/logo/logo-bottom1.png";
import LogosBottom2 from "../../assets/project-imgs/logo/logo-bottom2.png";
import Circle from "../../assets/project-imgs/portfoilio-actiity-circle.svg";
import Active from "../../assets/project-imgs/portfoilio-active.svg";
import Deactive from "../../assets/project-imgs/portfoilio-deactive.svg";
import ButtonArrow from "../../assets/project-imgs/Case-study-button-arrow.svg";
import ProjectCarouselModal from "./ProjectCarouselModal";
import MobileCarousel from "./MobileCarousel";
import RightArrow from "../../assets/project-imgs/Arrow/right.svg";
import LeftArrow from "../../assets/project-imgs/Arrow/left.svg";
import logo1 from "../../assets/project-imgs/logo/beauty-logo.svg";
import logo2 from "../../assets/project-imgs/logo/mbe-logo.svg";
import logo3 from "../../assets/project-imgs/logo/a2z-logo.svg";
import logo4 from "../../assets/project-imgs/logo/lyxa-logo.svg";
import { useEffect } from "react";

let projects = [
  {
    imgBottom: A2zMobile,
    imgTop: A2zWeb,
    logo: A2zLogo,
    title: "A2Z",
    desc: "A2Z offers the best virtual learning experience, test preps, classes, and papers for all students.",
    longDesc:
      "An online booking system designed by the Kadritech team for a beauty service provider. With or without a website, Beautyclub App helps the beauty service provider to easily manage their salon. The app helps Beautyclub manage their online bookings while they're able to channel their energy to their business as usual. Through the app, they will be able to accept bookings and fill cancellations on the internet and make the process of client scheduling seamless and hassle-free.",
  },
  {
    imgBottom1: BeautyClubApp1,
    imgBottom2: BeautyClubApp2,
    imgBottom3: BeautyClubWeb1,
    imgTop: BeautyClubWeb,
    logo: BeautyClubLogo,
    title: "Beautyclub",
    desc: "An online booking system designed by Kadritech team for a beauty services providers. With or without a website, Beautyclub App helps the beauty service provider to easily manage their salon.",
    longDesc:
      "An online booking system designed by the Kadritech team for a beauty service provider. With or without a website, Beautyclub App helps the beauty service provider to easily manage their salon. The app helps Beautyclub manage their online bookings while they're able to channel their energy to their business as usual. Through the app, they will be able to accept bookings and fill cancellations on the internet and make the process of client scheduling seamless and hassle-free.",
  },
  {
    imgBottom1: LyxaApp1,
    imgBottom2: LyxaApp2,
    imgBottom3: LyxaWeb1,
    imgTop: LyxaWeb,
    logo: LyxaLogo,
    title: "LYXA",
    desc: "LYXA is a full-service delivery company that caters for the African market. They deliver products ranging from groceries to pharmaceutical products to foods to their customers.",
    longDesc:
      "LYXA is a full-service delivery company that caters for the African market. They deliver products ranging from groceries to pharmaceutical products to foods to their customers. We designed and delivered three mobile applications; a user app, a store app, and a delivery boy app; and two web apps for Drop. These apps help connect consumers to their online marketplace where they can shop. It also allows Drop to fully manage and oversee the entire process.",
  },
  {
    imgBottom: PlatinumMobile,
    imgTop: PlatinumWeb,
    logo: PlatinumLogo,
    title: "Platinum",
    desc: "Platinum e-commerce web-app in the field of stationary and office supplies, working with top notch distributors across Europe, Middle East and Africa, supplying them with astonishing products.",
    longDesc:
      "Drop is a full-service delivery company that caters for the African market. They deliver products ranging from groceries to pharmaceutical products to foods to their customers. We designed and delivered three mobile applications; a user app, a store app, and a delivery boy app; and two web apps for Drop. These apps help connect consumers to their online marketplace where they can shop. It also allows Drop to fully manage and oversee the entire process.",
  },
  {
    imgBottom: MBEBottom,
    imgTop: MBETop,
    logo: MBELogo,
    title: "MBE",
    desc: "MBE application is a software system designed to facilitate the buying and selling of goods and services over an electronic network, such as the internet.",
    longDesc:
      "The Kadritech team designed and developed the Try & Carry App to make it easy for our e-commerce client to sell online. The Try & Carry App elevates our client's e-commerce business by allowing their customers to shop the way they want. It gives their online shop a competitive edge by allowing them to deliver a top-notch shopping experience.",
  },
  {
    imgBottom: NailzyBottom,
    imgTop: NailzyTop,
    logo: NailzyLogo,
    title: "Nailzy",
    desc: "Nailzi App is an all-in-one mobile app we designed and developed for our client in the beauty industry. It allows their customers to easily schedule beauty sessions with any of the listed",
    longDesc:
      "The Nailzi App is an all-in-one mobile app we designed and developed for our client in the beauty industry. It allows their customers to easily schedule beauty sessions with any of the listed Nailzi professionals right from the comfort of their homes. They provide nail services, eyelash, and waxing sessions.  We integrated a review system where their customers can drop feedbacks on any Nailzi professional used.",
  },
  {
    imgBottom1: LogosBottom1,
    imgBottom2: LogosBottom2,
    imgTop1: LogosTop1,
    imgTop2: LogosTop2,
    logo: Logo,
    title: "Logo Designs",
    desc: "Our logo designing is the process of creating a unique visual symbol that represents a brand, organization, or product.",
    longDesc:
      "The Nailzi App is an all-in-one mobile app we designed and developed for our client in the beauty industry. It allows their customers to easily schedule beauty sessions with any of the listed Nailzi professionals right from the comfort of their homes. They provide nail services, eyelash, and waxing sessions.  We integrated a review system where their customers can drop feedbacks on any Nailzi professional used.",
  },
  //add here

  //////////
];

//   let active = true;

function ProjectCarousel2() {
  const [active, setActive] = useState(true);
  const [projectIndex, setProjectIndex] = useState(0);
  const [counter, setCounter] = useState(0);
  const [status, setStatus] = useState(true);
  const [timer, setTimer] = useState({});
  const [open, setOpen] = useState(false);

// 

useEffect(() => {
    let timer2 = setTimeout(() => {
      handleNext(true);
      setStatus((state) => !state);
    }, 10000);
    setTimer(timer2);
  }, [status]);

  const handleNext = () => {
    if (active) {
    //   active = false;
      setActive(false)
      setCounter((state) => {
        return state + 1;
      });

      setTimeout(() => {
        setProjectIndex((state) => {
          if (state + 1 === projects.length) {
            return 0;
          } else {
            return state + 1;
          }
        });
        setCounter((state) => {
          if (state + 1 > projects.length * 2 - 2) {
            return 0;
          } else {
            return state + 1;
          }
        });
      }, 950);
      setTimeout(() => (setActive(true)), 2000);
    }
  };

  const handleBack = () => {
    if (active) {
     setActive(false);
      setCounter((state) => {
        return state + 1;
      });

      setTimeout(() => {
        setProjectIndex((state) => {
          if (state - 1 < 0) {
            return 0;
          } else {
            return state - 1;
          }
        });
        setCounter((state) => {
          if (state - 3 < 0) {
            return 0;
          } else {
            return state - 3;
          }
        });
      }, 1000);
      setTimeout(() => (setActive(true)), 2000);
    }
  };


  const handleOpen = () => {
    clearTimeout(timer);
    setOpen(true);
  };


  return (
    <div className="ProjectCarousel">
      <div className="ProjectCarousel-left">
        <motion.p
          className="ProjectCarousel-leftTitle"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
          }}
          // key={projectIndex}
        >
          {projects[projectIndex].title}
        </motion.p>

        <div className="circle">
          <div
            style={{
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="ProjectCarousel-middle">
              <motion.img
                id="logo-img"
                src={projects[projectIndex].logo}
                alt=""
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 1,
                }}
                // key={projectIndex}
              />
              <img id="project-circle" src={Circle} alt="" />
              {projectIndex === 0 && (
                <img id="project-active1" src={Active} alt="" />
              )}
              <img id="project-deactive1" src={Deactive} alt="" />
              {projectIndex === 1 && (
                <img id="project-active2" src={Active} alt="" />
              )}
              <img id="project-deactive2" src={Deactive} alt="" />
              {projectIndex === 2 && (
                <img id="project-active3" src={Active} alt="" />
              )}
              <img id="project-deactive3" src={Deactive} alt="" />
              {projectIndex === 3 && (
                <img id="project-active4" src={Active} alt="" />
              )}
              <img id="project-deactive4" src={Deactive} alt="" />
              {projectIndex === 4 && (
                <img id="project-active5" src={Active} alt="" />
              )}
              <img id="project-deactive5" src={Deactive} alt="" />
              {projectIndex === 5 && (
                <img id="project-active6" src={Active} alt="" />
              )}
              <img id="project-deactive6" src={Deactive} alt="" />
              {projectIndex === 6 && (
                <img id="project-active7" src={Active} alt="" />
              )}
              <img id="project-deactive7" src={Deactive} alt="" />
            </div>
          </div>
        </div>

        <div style={{ zindex: 3 }}>
          <div className="ProjectCarousel-leftAbout">
            <motion.p
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 1,
              }}
              // key={projectIndex}
            >
              {projects[projectIndex].desc}
            </motion.p>
            <div className="ViewCaseStudyBtn">
              <span onClick={handleOpen}>
                View Case Study{" "}
                <motion.img
                  whileHover={{ scale: 1.1, x: 5 }}
                  src={ButtonArrow}
                  alt=""
                />
              </span>
            </div>
          </div>
          <div className="ProjectCarousel-Arrow-container">
            <div className="ProjectCarousel-Arrow">
              <motion.img
                onClick={() => {
                  clearTimeout(timer);
                  setTimeout(() => setStatus((state) => !state));
                  handleBack();
                }}
                src={LeftArrow}
                alt=""
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 0 }}
              />
              <motion.img
                onClick={() => {
                  clearTimeout(timer);
                  setTimeout(() => setStatus((state) => !state));
                  handleNext("click");
                }}
                src={RightArrow}
                alt=""
                initial={{ opacity: 0.5 }}
                whileHover={{ opacity: 1 }}
              />
            </div>
          </div>
        </div>
      </div>
      {counter === 0 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={
              window.innerWidth <= "768"
                ? { y: "-135%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "-130%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "-130%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "-125%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "-127%" }
                : { y: "-127%" }
            }
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />

          <motion.img
            id="bottom-img"
            src={projects[projectIndex].imgBottom}
            alt=""
            initial={
              window.innerWidth <= "768"
                ? { y: "145%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "147%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "137%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "116%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "125%" }
                : { y: "125%" }
            }
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {counter === 1 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={{ y: 0 }}
            animate={
              window.innerWidth <= "768"
                ? { y: "-135%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "-130%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "-130%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "-125%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "-127%" }
                : { y: "-127%" }
            }
            transition={{
              duration: 1,
            }}
           
          />

          <motion.img
            id="bottom-img"
            src={projects[projectIndex].imgBottom}
            alt=""
            initial={{ y: 0 }}
            animate={
              window.innerWidth <= "768"
                ? { y: "145%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "147%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "137%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "116%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "125%" }
                : { y: "125%" }
            }
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}


      {counter === 2 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={
              window.innerWidth <= "768"
                ? { y: "-135%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "-130%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "-130%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "-125%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "-127%" }
                : { y: "-127%" }
            }
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />

          <motion.div
            initial={
              window.innerWidth <= "768"
                ? { y: "145%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "147%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "137%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "116%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "125%" }
                : { y: "125%" }
            }
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          >
            <MobileCarousel projects={projects} projectIndex={projectIndex} />
          </motion.div>
        </div>
      )}
      {counter === 3 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={{ y: 0 }}
            animate={
              window.innerWidth <= "768"
                ? { y: "-135%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "-130%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "-130%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "-125%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "-127%" }
                : { y: "-127%" }
            }
            transition={{
              duration: 1,
            }}
           
          />
          <motion.div
            initial={{ y: 0 }}
            animate={
              window.innerWidth <= "768"
                ? { y: "145%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "147%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "137%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "116%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "125%" }
                : { y: "125%" }
            }
            transition={{
              duration: 1,
            }}
           
          >
            <MobileCarousel projects={projects} projectIndex={projectIndex} />
          </motion.div>
        </div>
      )}
      {counter === 4 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={
              window.innerWidth <= "768"
                ? { y: "-135%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "-130%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "-130%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "-125%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "-127%" }
                : { y: "-127%" }
            }
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />

          <motion.div
            initial={
              window.innerWidth <= "768"
                ? { y: "145%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "147%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "137%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "116%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "125%" }
                : { y: "125%" }
            }
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          >
            <MobileCarousel projects={projects} projectIndex={projectIndex} />
          </motion.div>
        </div>
      )}
      {counter === 5 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={{ y: 0 }}
            animate={
              window.innerWidth <= "768"
                ? { y: "-135%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "-130%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "-130%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "-125%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "-127%" }
                : { y: "-127%" }
            }
            transition={{
              duration: 1,
            }}
           
          />
          <motion.div
            initial={{ y: 0 }}
            animate={
              window.innerWidth <= "768"
                ? { y: "145%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "147%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "137%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "116%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "125%" }
                : { y: "125%" }
            }
            transition={{
              duration: 1,
            }}
           
          >
            <MobileCarousel projects={projects} projectIndex={projectIndex} />
          </motion.div>
        </div>
      )}
      
      {counter === 6 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={
              window.innerWidth <= "768"
                ? { y: "-135%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "-130%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "-130%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "-125%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "-127%" }
                : { y: "-127%" }
            }
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />

          <motion.img
            id="bottom-img"
            src={projects[projectIndex].imgBottom}
            alt=""
            initial={
              window.innerWidth <= "768"
                ? { y: "145%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "147%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "137%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "116%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "125%" }
                : { y: "125%" }
            }
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {counter === 7 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={{ y: 0 }}
            animate={
              window.innerWidth <= "768"
                ? { y: "-135%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "-130%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "-130%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "-125%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "-127%" }
                : { y: "-127%" }
            }
            transition={{
              duration: 1,
            }}
           
          />

          <motion.img
            id="bottom-img"
            src={projects[projectIndex].imgBottom}
            alt=""
            initial={{ y: 0 }}
            animate={
              window.innerWidth <= "768"
                ? { y: "145%" }
                : window.innerWidth >= "769" && window.innerWidth <= "1023"
                ? { y: "147%" }
                : window.innerWidth >= "1024" && window.innerWidth <= "1200"
                ? { y: "137%" }
                : window.innerWidth >= "1201" && window.innerWidth <= "1599"
                ? { y: "116%" }
                : window.innerWidth >= "1600" && window.innerWidth <= "2400"
                ? { y: "125%" }
                : { y: "125%" }
            }
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {counter === 8 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img-app"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={{ y: "-94%" }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="bottom-img-app"
            src={projects[projectIndex].imgBottom}
            alt=""
            initial={{ y: "94%" }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {counter === 9 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img-app"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={{ y: 0 }}
            animate={{ y: "-94%" }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="bottom-img-app"
            src={projects[projectIndex].imgBottom}
            alt=""
            initial={{ y: 0 }}
            animate={{ y: "94%" }}
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {counter === 10 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img-app"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={{ y: "-94%" }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="bottom-img-app"
            src={projects[projectIndex].imgBottom}
            alt=""
            initial={{ y: "94%" }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {counter === 11 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img-app"
            src={projects[projectIndex].imgTop}
            alt=""
            initial={{ y: 0 }}
            animate={{ y: "-94%" }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="bottom-img-app"
            src={projects[projectIndex].imgBottom}
            alt=""
            initial={{ y: 0 }}
            animate={{ y: "94%" }}
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {counter === 12 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img1"
            src={logo1}
            alt=""
            initial={{ y: "-49vh" }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />

          <motion.img
            id="top-img2"
            src={logo2}
            alt=""
            initial={{ y: "-49vh" }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="bottom-img1"
            src={logo3}
            alt=""
            initial={{ y: "49vh" }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="bottom-img2"
            src={logo4}
            alt=""
            initial={{ y: "49vh" }}
            animate={{ y: 0 }}
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {counter === 13 && (
        <div className="ProjectCarousel-right">
          <motion.img
            id="top-img1"
            src={logo1}
            alt=""
            initial={{ y: 0 }}
            animate={{ y: "-49vh" }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="top-img2"
            src={logo2}
            alt=""
            initial={{ y: 0 }}
            animate={{ y: "-49vh" }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="bottom-img1"
            src={logo3}
            alt=""
            initial={{ y: 0 }}
            animate={{ y: "49vh" }}
            transition={{
              duration: 1,
            }}
           
          />
          <motion.img
            id="bottom-img2"
            src={logo4}
            alt=""
            initial={{ y: 0 }}
            animate={{ y: "49vh" }}
            transition={{
              duration: 1,
            }}
           
          />
        </div>
      )}
      {/* Modal */}
      {open && (
        <ProjectCarouselModal
          open={open}
          setOpen={setOpen}
          projectIndex={projectIndex}
          setProjectIndex={setProjectIndex}
          projects={projects}
          setCounter={setCounter}
          setStatus={setStatus}
        />
      )}
    </div>
  );
}

export default ProjectCarousel2;
