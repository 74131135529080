import React, { useState } from "react";
import "./testimonialSection.scss";
import client1 from "../../assets/testimonials/client1.png";
import client2 from "../../assets/testimonials/client2.png";
import client3 from "../../assets/testimonials/client3.png";
import client4 from "../../assets/testimonials/client4.jpg";
import star from "../../assets/testimonials/star.svg";
import TestimonialCarousel from "./TestimonialCarousel";

const Card = ({ review }) => (
  <div className="card">
    <blockquote className="TestimonialSectionBlockquote">
      {review.desc}
    </blockquote>
    <div className="TestimonialSectionRightMsgDetails">
      <div className="TestimonialSectionRightMsgProfile">
        <img src={review.avatar} alt="" />
        <div className="TestimonialSectionRightMsgProfileInfo">
          <span>{review.name}</span>
          <p>{review.occupation}</p>
        </div>
      </div>
      <div className="TestimonialSectionRightMsgStar">
        <img src={star} alt="" />
      </div>
    </div>
  </div>
);

const reviews = [
  {
    desc: "Working with Kadritech team to create our mobile app was a great experience. They are highly knowledgeable and provided us with the best solution for our needs. Their team of experts delivered on time and within budget. We are very pleased with the quality of the final product and would highly recommend them for any mobile app development project.",
    avatar: client1,
    occupation: "CEO",
    name: "Damian Bishop",
  },

  {
    desc: "I was looking for a logo design for my business and I was so impressed by the design that I received from Kadritech. The designer was very attentive to my needs, and the logo they created was exactly what I wanted. It was perfect! I highly recommend Kadritech for any logo design needs.",
    avatar: client2,
    occupation: "Executive Manager",
    name: "Alayna Joplin",
  },
  {
    desc: "Kadritech have made our business seamless for us by bringing our salon business online to our customers. Thanks to them, our customers can now book appointments with us and select all they need to be done right from the comfort of their homes. We're happy with our mobile app.",
    avatar: client3,
    occupation: "Business owner",
    name: "Griffin Paine",
  },
  {
    desc: "We hired Kadritech to create a website for our business, and we couldn't be happier with the results. They worked with us to create a unique and professional website that met all of our needs. The team was very knowledgeable and provided helpful advice and feedback throughout the entire process. We highly recommend Kadritech for any website design project!",
    avatar: client4,
    occupation: "Marketing Manager",
    name: "Chris Harsh",
  },
];

function TestimonialSection() {
  const [counter, setCounter] = useState(0);

  return (
    <div className="TestimonialSection">
      <div className="TestimonialSectionLeft">
        <span>Client success is our success</span>
        <p>
          Client satisfaction is our ultimate goal. They have a shared a few
          kind words to express their satisfaction with service. Here is what
          they have to say.
        </p>
      </div>
      <div className="TestimonialSectionRight">
        <div className="TestimonialSectionRightMsg">
          <TestimonialCarousel>
            {reviews.map((review, i) => (
              <Card key={i} review={review} />
            ))}
          </TestimonialCarousel>
        </div>
      </div>
    </div>
  );
}

export default TestimonialSection;
