import React, { useState } from "react";
import "./NewFooter.scss";
import rightArrowLine from "../../assets/footer/NewIcon/arrow right/arrow line.svg";
import rightArrowSm from "../../assets/footer/NewIcon/arrow right/arrow right.svg";
import upperArrow from "../../assets/footer/NewIcon/arrow up/arrow up.svg";
import upperArrowLine from "../../assets/footer/NewIcon/arrow up/arrow up line.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'
// import { faCirclePhone } from "@fortawesome/free-regular-svg-icons";

import logo from "../../assets/logo.png";
// import fb from "../../assets/footer/NewIcon/fb.svg";
// import linkedin from "../../assets/footer/NewIcon/in.svg";
// import insta from "../../assets/footer/NewIcon/insta.svg";
// import wa from "../../assets/footer/NewIcon/wa.svg";
const NewFoter = () => {
  function scrollToTop() {
    document
      .getElementsByClassName("App")[0]
      .scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  function contactUs() {
    var height = document.getElementsByClassName("Header")[0].offsetHeight + 15;
    var container = document.getElementsByClassName("App")[0];
    var offsetTop;
    offsetTop =
      document.getElementsByClassName("ContactUsSection")[0].offsetTop - height;
    container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
  }

  function openNewWindow(url) {
    window.open(url, '_blank');
  }

  return (
    <div className="new-footer">
      <div className="new-footer--container">
        <div className="new-footer--content new-footer--brand">
          <div className="new-footer--brand--image">
            <img src={logo} alt="brand_logo_here"></img>
          </div>
          <span className="Footer-text">
          <h5 className="copyRightText">
            © {new Date().getFullYear()} Kadritech AB - Org.nr 559364-1391
          </h5>
        </span>
        </div>
        <div className="new-footer--content new-footer--location">
          <div className="new-footer--text_box">
            <h2>Location</h2>
            <p>Morupsgränd 11 <br></br>12573, Stockholm, Sweden</p>
          </div>
        </div>
        <div className="new-footer--content new-footer--email">
          <div className="new-footer--text_box">
            <h2>E-mail</h2>
            <a href="mailto:info.MedTech@kadritech.se">info@kadritech.se</a>
          </div>
        </div>

        <div className="new-footer--content new-footer--social">
          <div className="new-footer--social--icon">
            <div className="new-footer--social--icon_main" onClick={() => openNewWindow("https://www.facebook.com/kadritech")} >
              {/* <img src={fb}></img> */}
              <svg
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="39"
                  cy="39"
                  r="38"
                  stroke="#D9D9D9"
                  strokeWidth="2"
                />
                <path
                  d="M41.704 40.9423H45.5408L47.0755 34.8034H41.704V31.7339C41.704 30.1532 41.704 28.6645 44.7734 28.6645H47.0755V23.5078C46.5752 23.4418 44.686 23.293 42.6908 23.293C38.524 23.293 35.5651 25.836 35.5651 30.5062V34.8034H30.9609V40.9423H35.5651V53.9874H41.704V40.9423Z"
                  fill="#D9D9D9"
                />
              </svg>
            </div>
            <div className="new-footer--social--icon_main" onClick={() => openNewWindow("https://wa.me/message/H3HNRJNX2HZ3A1")}>
              {/* <img src={wa}></img> */}
              <svg
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="39"
                  cy="39"
                  r="38"
                  stroke="#D9D9D9"
                  strokeWidth="2"
                />
                <path
                  d="M49.4575 27.7591C48.0504 26.3378 46.3745 25.211 44.5274 24.4442C42.6803 23.6774 40.699 23.2861 38.6991 23.2931C30.3195 23.2931 23.49 30.1226 23.49 38.5022C23.49 41.1879 24.1959 43.797 25.5158 46.099L23.3672 53.9875L31.4245 51.8696C33.6498 53.082 36.1514 53.7266 38.6991 53.7266C47.0786 53.7266 53.9082 46.8971 53.9082 38.5175C53.9082 34.4505 52.3274 30.629 49.4575 27.7591ZM38.6991 51.1483C36.4277 51.1483 34.2023 50.5344 32.2532 49.3833L31.7928 49.1071L27.0045 50.3656L28.2783 45.7L27.9714 45.2242C26.7094 43.2091 26.0394 40.8798 26.0376 38.5022C26.0376 31.5345 31.7161 25.856 38.6837 25.856C42.0601 25.856 45.237 27.1759 47.6158 29.5701C48.7937 30.7425 49.7271 32.1371 50.362 33.673C50.9969 35.2089 51.3207 36.8556 51.3145 38.5175C51.3452 45.4851 45.6667 51.1483 38.6991 51.1483ZM45.636 41.6944C45.2523 41.5102 43.38 40.5894 43.0423 40.4513C42.6893 40.3285 42.4438 40.2671 42.1829 40.6354C41.922 41.0191 41.2007 41.8785 40.9858 42.1241C40.7709 42.385 40.5407 42.4157 40.157 42.2162C39.7734 42.032 38.5456 41.6176 37.103 40.3285C35.9673 39.3156 35.2152 38.0724 34.985 37.6888C34.7702 37.3051 34.9543 37.1056 35.1539 36.906C35.3227 36.7372 35.5375 36.461 35.7217 36.2461C35.9059 36.0313 35.9826 35.8624 36.1054 35.6169C36.2282 35.356 36.1668 35.1411 36.0747 34.957C35.9826 34.7728 35.2152 32.9004 34.9083 32.1331C34.6014 31.3964 34.2791 31.4885 34.0489 31.4731H33.3122C33.0513 31.4731 32.6523 31.5652 32.2993 31.9489C31.9616 32.3326 30.9794 33.2534 30.9794 35.1258C30.9794 36.9981 32.3453 38.8091 32.5295 39.0547C32.7136 39.3156 35.2152 43.1524 39.0214 44.7945C39.9268 45.1935 40.6328 45.4238 41.1853 45.5926C42.0908 45.8842 42.9195 45.8381 43.5795 45.746C44.3161 45.6386 45.8355 44.8252 46.1425 43.9351C46.4648 43.0449 46.4648 42.2929 46.3573 42.1241C46.2499 41.9553 46.0197 41.8785 45.636 41.6944Z"
                  fill="#D9D9D9"
                />
              </svg>
            </div>
            <div className="new-footer--social--icon_main" onClick={() => openNewWindow("https://www.instagram.com/kadritech")}>
              {/* <img src={insta}></img> */}
              <svg
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="39"
                  cy="39"
                  r="38"
                  stroke="#D9D9D9"
                  strokeWidth="2"
                />
                <path
                  d="M32.1905 23.293H45.0821C49.9932 23.293 53.9835 27.2832 53.9835 32.1944V45.086C53.9835 47.4468 53.0457 49.7109 51.3764 51.3803C49.707 53.0496 47.4429 53.9874 45.0821 53.9874H32.1905C27.2793 53.9874 23.2891 49.9971 23.2891 45.086V32.1944C23.2891 29.8336 24.2269 27.5695 25.8962 25.9001C27.5656 24.2308 29.8297 23.293 32.1905 23.293ZM31.8835 26.3624C30.4182 26.3624 29.0129 26.9445 27.9767 27.9806C26.9406 29.0168 26.3585 30.4221 26.3585 31.8874V45.393C26.3585 48.4471 28.8294 50.918 31.8835 50.918H45.3891C46.8544 50.918 48.2597 50.3359 49.2958 49.2997C50.332 48.2636 50.9141 46.8583 50.9141 45.393V31.8874C50.9141 28.8333 48.4432 26.3624 45.3891 26.3624H31.8835ZM46.6936 28.6645C47.2024 28.6645 47.6903 28.8666 48.0501 29.2264C48.4099 29.5862 48.612 30.0741 48.612 30.5829C48.612 31.0917 48.4099 31.5796 48.0501 31.9394C47.6903 32.2992 47.2024 32.5013 46.6936 32.5013C46.1848 32.5013 45.6968 32.2992 45.3371 31.9394C44.9773 31.5796 44.7752 31.0917 44.7752 30.5829C44.7752 30.0741 44.9773 29.5862 45.3371 29.2264C45.6968 28.8666 46.1848 28.6645 46.6936 28.6645ZM38.6363 30.9666C40.6715 30.9666 42.6233 31.775 44.0623 33.2141C45.5014 34.6532 46.3099 36.605 46.3099 38.6402C46.3099 40.6754 45.5014 42.6272 44.0623 44.0663C42.6233 45.5053 40.6715 46.3138 38.6363 46.3138C36.6011 46.3138 34.6493 45.5053 33.2102 44.0663C31.7711 42.6272 30.9627 40.6754 30.9627 38.6402C30.9627 36.605 31.7711 34.6532 33.2102 33.2141C34.6493 31.775 36.6011 30.9666 38.6363 30.9666ZM38.6363 34.036C37.4152 34.036 36.2441 34.5211 35.3806 35.3846C34.5172 36.248 34.0321 37.4191 34.0321 38.6402C34.0321 39.8613 34.5172 41.0324 35.3806 41.8958C36.2441 42.7593 37.4152 43.2444 38.6363 43.2444C39.8574 43.2444 41.0285 42.7593 41.8919 41.8958C42.7554 41.0324 43.2405 39.8613 43.2405 38.6402C43.2405 37.4191 42.7554 36.248 41.8919 35.3846C41.0285 34.5211 39.8574 34.036 38.6363 34.036Z"
                  fill="#D9D9D9"
                />
              </svg>
            </div>
            <div className="new-footer--social--icon_main" onClick={() => openNewWindow("https://www.linkedin.com/company/78344732/")}>
              {/* <img src={linkedin}></img> */}
              <svg
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="39"
                  cy="39"
                  r="38"
                  stroke="#D9D9D9"
                  strokeWidth="2"
                />
                <path
                 fillRule="evenodd"
                 clipRule="evenodd"
                  d="M34.6905 33.9884H40.3905V36.8277C41.2116 35.1947 43.3172 33.7275 46.4803 33.7275C52.5439 33.7275 53.9835 36.9781 53.9835 42.942V53.9874H47.8446V44.3002C47.8446 40.9039 47.0235 38.9886 44.9333 38.9886C42.0342 38.9886 40.8294 41.0528 40.8294 44.2987V53.9874H34.6905V33.9884ZM24.1639 53.7265H30.3027V33.7275H24.1639V53.7265ZM31.1821 27.2065C31.1824 27.7211 31.0803 28.2305 30.8819 28.7053C30.6835 29.1801 30.3928 29.6107 30.0265 29.9721C29.2843 30.7097 28.2797 31.1226 27.2333 31.1201C26.1888 31.1194 25.1865 30.7075 24.4432 29.9736C24.0782 29.611 23.7884 29.1799 23.5904 28.7051C23.3924 28.2302 23.29 27.721 23.2891 27.2065C23.2891 26.1675 23.7034 25.173 24.4447 24.4394C25.1873 23.7045 26.1901 23.2925 27.2348 23.293C28.2815 23.293 29.2852 23.7058 30.0265 24.4394C30.7662 25.173 31.1821 26.1675 31.1821 27.2065Z"
                  fill="#D9D9D9"
                />
              </svg>
            </div>
            <div className="new-footer--social--icon_main" onClick={() => openNewWindow("tel:+46722994077")}>
              {/* <img src={linkedin}></img> */}
              <svg
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M78 39C78 60.5391 60.5391 78 39 78C17.4609 78 0 60.5391 0 39C0 17.4609 17.4609 0 39 0C60.5391 0 78 17.4609 78 39ZM2.73 39C2.73 59.0314 18.9686 75.27 39 75.27C59.0314 75.27 75.27 59.0314 75.27 39C75.27 18.9686 59.0314 2.73 39 2.73C18.9686 2.73 2.73 18.9686 2.73 39Z"
                  fill="#D9D9D9"
                />
                <g clipPath="url(#clip0_139_13)">
                  <path
                    d="M57.8586 47.2659L49.1086 43.5159C48.7348 43.3566 48.3194 43.323 47.9249 43.4202C47.5303 43.5175 47.1781 43.7402 46.9211 44.055L43.0461 48.7893C36.9646 45.922 32.0705 41.0278 29.2031 34.9464L33.9375 31.0714C34.2529 30.8149 34.4761 30.4626 34.5734 30.0679C34.6706 29.6732 34.6366 29.2575 34.4766 28.8839L30.7266 20.1339C30.5509 19.7311 30.2401 19.4022 29.8479 19.2039C29.4557 19.0057 29.0066 18.9505 28.5781 19.0479L20.4531 20.9229C20.04 21.0183 19.6714 21.251 19.4074 21.5828C19.1435 21.9147 18.9999 22.3262 19 22.7503C19 42.7893 35.2422 59.0003 55.25 59.0003C55.6742 59.0005 56.0859 58.857 56.4179 58.5931C56.7499 58.3291 56.9827 57.9604 57.0781 57.5471L58.9531 49.4221C59.0499 48.9915 58.9936 48.5407 58.7938 48.1471C58.594 47.7536 58.2633 47.4419 57.8586 47.2659Z"
                    fill="#D9D9D9"
                  />
                </g>

              </svg>
            </div>
          </div>
        </div>
        <div
          className="new-footer--content new-footer--back_to_top "
          onClick={scrollToTop}
        >
          <div className="new-footer--back_to_top--container">
            <div className="new-footer--back_to_top--top">
              <div className="new-footer--back_to_top--image_container">
                <img className="arrow-up" src={upperArrow} alt="upper_arrow"></img>
                <img className="arrow-up--line" src={upperArrowLine} alt="upper_arrow"></img>
                {/* <div className="custom--line2"></div> */}
              </div>
            </div>
            <div className="new-footer--back_to_top--bottom">
              <div className="new-footer--back_to_top--text_container">
                <p>back to top</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="new-footer--want_to_contact" onClick={contactUs}>
        <h2> Want to contact ? </h2>
        <div className="new-footer--want_to_contact--img_container">
          {/* <img src={isHovered ? rightArrowBg : rightArrowSm} alt="svg-img" /> */}
          <img className="arrow-right" src={rightArrowSm} alt="svg-img" />
          <img className="arrow-right--line" src={rightArrowLine} alt="svg-img" />

          {/* <div className="custom--line"></div> */}
        </div>
      </div>
    </div>
  );
};

export default NewFoter;
