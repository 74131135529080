import React from "react";
import "./NewServicesSection.scss";
import NewServiceSectionCard from "./NewServiceSectionCard";
import mobileApp from "../../assets/service-imgs/mobile_app forground.png";
import mobileAppIcon from "../../assets/service-imgs/new icon/mobileapp.png";
import uiux from "../../assets/service-imgs/ui-ux-design forground.png";
import uiuxIcon from "../../assets/service-imgs/new icon/ui-ux.png";
import web from "../../assets/service-imgs/webservice forground.png";
import webIcon from "../../assets/service-imgs/new icon/webservice.png";
import googleWeb from "../../assets/service-imgs/google service forground.png";
import googleWebIcon from "../../assets/service-imgs/new icon/googleservice.png";
import graphics from "../../assets/service-imgs/graphics forground.png";
import graphicsIcon from "../../assets/service-imgs/new icon/graphics.png";
// Modal Images
// type1
import mobileModal from "../../assets/service-imgs/modal/mobileapp.png";
import uiuxModal from "../../assets/service-imgs/modal/uiux.png";
import graphicsModal from "../../assets/service-imgs/modal/graphics.png";
import googleModal from "../../assets/service-imgs/modal/googleservices.png";
// type2
import webModal from "../../assets/service-imgs/modal/web.png";
import webAppModal from "../../assets/service-imgs/modal/webapp.png";

const WebAppModalContent = (props) => {
  return (
    <div>
      <div style={{ display: "flex", gap: "1em", alignContent: "center" }}>
        <div className="service-card-items--icon">
          <img
            src={props.icon}
            onLoad={props.handleIsLoading}
            alt="service_icon"
          ></img>
        </div>
        <div className="web-modal--textBox">
          <h2>{props.title}</h2>
        </div>
      </div>

      <div className="web-modal--table">
        <div className="web-modal--table--left">
          <table className="web-modal--table--box">
            <tr className="web-modal--table--header">
              <th>{props.listTitle1}</th>
            </tr>
            {props.list1.map((item, index) => (
              <tr key={index} className="web-modal--table--data">
                <td>{item}</td>
              </tr>
            ))}
            <tr className="web-modal--table--data web-modal--table--btn">
              <td>
                <button onClick={props.contactUs}>details & prices</button>
              </td>
            </tr>
          </table>
        </div>
        <div className="web-modal--table--right">
          <table className="web-modal--table--box">
            <tr className="web-modal--table--header">
              <th>{props.listTitle2}</th>
            </tr>
            {props.list2.map((item, index) => (
              <tr key={index} className="web-modal--table--data">
                <td>{item}</td>
              </tr>
            ))}
            <tr className="web-modal--table--data web-modal--table--btn">
              <td>
                <button onClick={props.contactUs}>details & prices</button>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="web-modal--desc">
        <div className="web-modal--desc--title">
          <h2>Web Development</h2>
        </div>
        <div className="web-modal--desc--main">
          <p>
            Your SEO journey begins with website development. And it’s important
            you’re spot on from the start. Create a remarkable impression of
            your business by developing a fully functional website. Kadritech is
            your one-stop digital agency for web development services that are
            unique to your industry/business. From designing a friendly UI/UX to
            developing highly-responsive and mobile-compatible websites, our
            in-house web developers can combine their expertise with your
            visions to integrate relevant features and enhance performance and
            usability.
          </p>
        </div>
      </div>
    </div>
  );
};


// NewServicesSection Component

const NewServicesSection = () => {
  const modalType1 = [
    {
      id: 1,
      image: mobileModal,
      desc: "Mobile app development is about developing fully responsive apps that can run smoothly on any mobile operating system – iOS and Android. From knowing your preferred users experience to creating a friendly UI, we can help. We will give you the best technology for your business by diving into its ecosystem and exploring your industry, capabilities, and requirements. We can build a custom strategy that will deliver the benefits of mobile technology to your business.",
    },
    {
      id: 2,
      image: uiuxModal,
      desc: "What makes a good UI/UX design is the ability to put your customers first. This involves creating an intuitive interface that makes using your platform as smooth and easy as possible for them. Kadritech’s expert UI/UX designers can help you design custom mobile and web apps with all the functionalities you’ll ever need to do your business online. We pride ourselves in designing creative, industry-standard, and user-friendly GUIs that make navigating your platform a beautiful experience for your consumers.",
    },
    {
      id: 3,
      image: graphicsModal,
      desc: "Studies have shown that posts with graphical content get more engagement. Reach out to your customers on digital platforms with bespoke graphic designs. From the idea development stage to the delivery of top-notch designs that enhance your brand image across your platforms, we can successfully onboard you to success. Our expert graphic designers will offer you excellent and seamless graphic design solutions that immediately stamp your brand in your customers’ minds.",
    },
  ];

  const modalType2 = [
    {
      id: 1,
      title: "websites",
      image: webModal,
      content: WebAppModalContent,
      listTitle1: "Wordpress Basic website",
      listTitle2: "customised website",
      list1: [
        "Choose from hundreds of creative designs",
        "Make some modifications to the design",
        "Simple logo design",
        "Add site content",
        "The website is in both Arabic and English",
        "SA/Com Domain Reservation",
        "SSL security certificate",
        "Create an infinite number of emails in the name of the domain",
        "Hosting for a year Unlimited",
        "Special professional design for the website interface",
      ],
      list2: [
        "Special professional design for the website interface",
        "Professional Logo Design",
        "Create or add website content",
        "Create professional forms",
        "The website is in both Arabic and English",
        "SA/Com Domain Reservation",
        "SSL security certificate",
        "Create an infinite number of emails in the name of the domain",
        "Hosting for a year Unlimited",
        "Special professional design for the website interface",
      ],
    },

    {
      id: 2,
      title: "web app",
      image: webAppModal,
      content: WebAppModalContent,
      listTitle1: "basic (ecommerce wordpress)",
      listTitle2: "advanced",
      list1: [
        "Special professional design for the website interface",
        "Professional Logo Design",
        "Create or add website content",
        "Create professional forms",
        "The website is in both Arabic and English",
        "SA/Com Domain Reservation",
        "SSL security certificate",
        "Create an infinite number of emails in the name of the domain",
        "Hosting for a year Unlimited",
        "Special professional design for the website interface",
      ],
      list2: [
        "Choose from hundreds of creative designs",
        "Make some modifications to the design",
        "Simple logo design",
        "Add site content",
        "The website is in both Arabic and English",
        "SA/Com Domain Reservation",
        "SSL security certificate",
        "Create an infinite number of emails in the name of the domain",
        "Hosting for a year Unlimited",
        "Special professional design for the website interface",
      ],
    },
  ];

  const modalType3 = [
    {
      id: 1,
      image: googleModal,
      title1: "Google Workspace",
      desc1:
        "Get all your work done in one placeand increase productivity byconnecting your team remotely ina secure working environment",
      title2: "Google Cloud",
      desc2:
        "Bridging innovation from wherever you are to the industry you want to lead",
    },
  ];

  return (
    <div className="new-services-section">
      <div className="lg-device-service-section">
        <div className="services-container">
          <div className="service-item service-item--large">
            <div className="services-card services-card--large border_top_left">
              <NewServiceSectionCard
                icon={mobileAppIcon}
                image={mobileApp}
                title={"mobile app development"}
                desc={"Looking for a universal and highly-responsive mobile app?"}
                moreBtnType={true}
                border={"border_top_left"}
                modalType={1}
                modalInfo={modalType1[0]}
              />
            </div>
            <div className="services-card">
              <NewServiceSectionCard
                icon={uiuxIcon}
                image={uiux}
                title={"UI & UX Design"}
                desc={"Want to improve your user experience?"}
                moreBtnType={true}
                modalType={1}
                modalInfo={modalType1[1]}
              />
            </div>
          </div>
          <div className="service-item service-item-small">
            <NewServiceSectionCard
              icon={webIcon}
              image={web}
              title={"Web Services"}
              desc={
                "Looking for the best web development solutions for your service website?"
              }
              moreBtnType={false}
              modalType={2}
              modalInfo={modalType2[0]}
              modalInfo2={modalType2[1]}
              border={"border_top_right border_bottom_right"}
            />
          </div>
          <div className="service-item service-item--large">
            <div className="services-card">
              <NewServiceSectionCard
                icon={googleWebIcon}
                image={googleWeb}
                title={"Google service"}
                desc={
                  "Looking to get all your work done in one place and increase productivity?"
                }
                moreBtnType={true}
                modalType={3}
                border={"border_bottom_left"}
                modalInfo={modalType3[0]}
              />
            </div>
            <div className="services-card services-card--large">
              <NewServiceSectionCard
                icon={graphicsIcon}
                image={graphics}
                title={"Graphic Design"}
                desc={
                  "Looking to connect with your customers with exquisite graphic designs?"
                }
                moreBtnType={true}
                modalType={1}
                modalInfo={modalType1[2]}
              />
            </div>
          </div>
        </div>
      </div>
      {/* For Phone */}
      <div className="sm-device-service-section">

        <div className="services-container">
          <div className="service-item service-item--large">
            <div className="services-card services-card--large border_top_left service-1">
              <NewServiceSectionCard
                icon={mobileAppIcon}
                image={mobileApp}
                title={"mobile app development"}
                desc={"Looking for a universal and highly-responsive mobile app?"}
                moreBtnType={true}
                border={"border_top_left"}
                modalType={1}
                modalInfo={modalType1[0]}
              />
            </div>
            <div className="services-card service-2">
              <NewServiceSectionCard
                icon={uiuxIcon}
                image={uiux}
                title={"UI & UX Design"}
                desc={"Want to improve your user experience?"}
                moreBtnType={true}
                modalType={1}
                modalInfo={modalType1[1]}
              />
            </div>
          </div>
          <div className="service-item service-item-small service-3">
            <NewServiceSectionCard
              icon={webIcon}
              image={web}
              title={"Web Services"}
              desc={
                "Looking for the best web development solutions for your service website?"
              }
              moreBtnType={false}
              modalType={2}
              modalInfo={modalType2[0]}
              modalInfo2={modalType2[1]}
              border={"border_top_right border_bottom_right"}
            />
          </div>
          <div className="service-item service-item--large ">
            <div className="services-card service-4">
              <NewServiceSectionCard
                icon={googleWebIcon}
                image={googleWeb}
                title={"Google service"}
                desc={
                  "Looking to get all your work done in one place and increase productivity?"
                }
                moreBtnType={true}
                modalType={3}
                border={"border_bottom_left"}
                modalInfo={modalType3[0]}
              />
            </div>
            <div className="services-card services-card--large service-5">
              <NewServiceSectionCard
                icon={graphicsIcon}
                image={graphics}
                title={"Graphic Design"}
                desc={
                  "Looking to connect with your customers with exquisite graphic designs?"
                }
                moreBtnType={true}
                modalType={1}
                modalInfo={modalType1[2]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewServicesSection;
