import React from "react";
import "./aboutUs.scss";
import aboutUs from "../../assets/aboutUs/kadritech-image.png";
import quotation from "../../assets/blockQuote/invertComma.png";
import { useStateValue } from "../../redux/StateProvider";

function AboutUsSection() {
  const [{ language }] = useStateValue();

  const navigate = () => {
    var height = document.getElementsByClassName("Header")[0].offsetHeight + 15;
    var container = document.getElementsByClassName("App")[0];
    var offsetTop =
      document.getElementsByClassName("ContactUsSection")[0].offsetTop - height;
    container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
  };

  return (
    <div className="AboutUsSectionMain">
      <div className="AboutUsSectionLeft">
        <div
          className="AbouteSectionQuatationIconBox"
          style={{ display: "flex", gap: "20px",justifyContent:"center",alignContent:"center", alignItems:"center",marginBottom:"1.2em" }}
        >
         <div className="AbouteSectionQuatationIcon">
         <img src={quotation} alt="quoteationmark"></img>
         </div>
          <div className="AboutUsSectionTitle" style={{flex:1}}>
            <span className="AboutUsSectionName">
             About Us
            </span>
          </div>
        </div>

        <div className="AboutSectionMsg-container">
          <div className="AboutSectionMsg">
            {language === "English" ? (
              <p>
                Welcome to KadriTech, when we created Kadritech over the years
                we had a new concept in mind for a Web & Digital agency. Our
                idea was not only to create web solutions and services but
                rather our goal has always been to create a synergy with our
                clients and to transform their business model and marketing
                goals into a viable solution for the online market. It is our
                responsibility to educate and help our customers succeed in the
                digital culture by adding value in their field.
              </p>
            ) : language === "Swedish" ? (
              <p>
                Välkommen till KadriTech, när vi skapade Kadritech genom åren
                hade vi ett nytt koncept i åtanke för en Web & Digital-byrå. Vår
                idé var inte bara att skapa webblösningar och tjänster utan
                snarare har vårt mål alltid varit att skapa en synergi med våra
                kunder och att omvandla deras affärsmodell och
                marknadsföringsmål till en hållbar lösning för onlinemarknaden.
                Det är vårt ansvar att utbilda och hjälpa våra kunder att lyckas
                i den digitala kulturen genom att tillföra värde inom sitt
                område.
              </p>
            ) : null}
          </div>
        </div>

        <div className="AboutSectionMsg2">
         <p>
         Kadritech is a full-service digital agency that deploys the best of
          Web 2.0 solutions to ensure that clients’ design and development needs
          are met. We help our clients to design top-notch digital products that
          create seamless user experiences across various platforms and devices.
          Our services include UI/UX Design
         </p>
        </div>
      </div>
      <div className="AboutUsSectionRight">
        {/* <img src={aboutUs}></img> */}
      </div>
    </div>
  );
}

export default AboutUsSection;
